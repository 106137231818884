import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faRepeat,
  faGlobe,
  faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ButtonUI from "../../components/ui/button/button";
import { useTranslation } from "react-i18next";

export default function MailModal(props) {
  const { open, setOpen, mailShow } = props;
  const webPath = useSelector((state) => state.app.webPath);
  const { t } = useTranslation("mailinbox-page");

  return (
    <>
      <Modal
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="mailinbox-modal">
          <section id="mailinbox-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <Typography className="head-title">
                    <FontAwesomeIcon icon={faCircleInfo} /> {t("ข้อมูล")}
                  </Typography>
                </div>
                <div className="head-right">
                  <h2 className="head-title">
                    <Typography>
                      <FontAwesomeIcon
                        icon={mailShow.type_id === 1 ? faGlobe : faRepeat}
                      />{" "}
                      {mailShow.type_id === 1
                        ? "สมัครอินเตอร์เน็ตไฟเบอร์"
                        : "ย้ายค่าย"}
                    </Typography>
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  className="mail-details"
                  sx={{
                    width: "100%",
                    display: "grid",
                    gap: 1,
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <Typography variant="subtitle1">
                    ประเภทการติดต่อ :{" "}
                    {mailShow.type_id === 1
                      ? "Internet Fiber"
                      : "ย้ายค่ายเบอร์เดิม"}
                  </Typography>
                  <Typography variant="subtitle1">
                    ชื่อ - สกุล : {mailShow.firstname + " " + mailShow.lastname}
                  </Typography>
                  <Typography variant="subtitle1">
                    เบอร์โทรติดต่อ : {mailShow.phone_number}
                  </Typography>
                  {mailShow.type_id === 2 && (
                    <Typography variant="subtitle1">
                      เบอร์ที่ต้องการย้าย : {mailShow.phone_move}
                    </Typography>
                  )}
                  <Typography variant="subtitle1">
                    อีเมล์ : {mailShow.email}
                  </Typography>
                  <Typography variant="subtitle1">
                    ไลน์ไอดี : {mailShow.line_id}
                  </Typography>
                  {mailShow.type_id === 1 && (
                    <>
                      <Typography variant="subtitle1">
                        อำเภอ/เขต : {mailShow.district}
                      </Typography>
                      <Typography variant="subtitle1">
                        ตำบล/แขวง : {mailShow.subdistrict}
                      </Typography>
                      <Typography variant="subtitle1">
                        จังหวัด : {mailShow.province}
                      </Typography>
                      <Typography variant="subtitle1">
                        รหัสไปรษณีย์ : {mailShow.zip_code}
                      </Typography>
                      <Typography variant="subtitle1">
                        สถานที่ติดตั้ง (ละติจูด,ลองจิจูด) : {"[" + mailShow.lat_lng.trim() + "]"} <br />
                        <Typography variant="subtitle1">
                            รายละเอียดที่อยู่ : {mailShow.address}
                        </Typography>
                        <a
                          href={
                            "https://www.google.com/maps/place/" +
                            mailShow.lat_lng.trim()
                          }
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faMapLocationDot} /> ดูที่
                          Google Maps
                        </a> <br />
                      </Typography>
                    </>
                  )}
                </Box>

                <TableContainer component={Paper} className="table">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">ID สินค้า</TableCell>
                        <TableCell>ชื่อสินค้า</TableCell>
                        <TableCell>รูปสินค้า</TableCell>
                        <TableCell>ราคา (บาท)</TableCell>
                        <TableCell>แพ็คเกจ</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          height: "100px",
                        }}
                      >
                        <TableCell align="right">
                          {mailShow.product.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {mailShow.product.title}
                        </TableCell>
                        <TableCell>
                          {mailShow.product.thumbnail_link && (
                            <img
                              src={webPath + mailShow.product.thumbnail_link}
                              alt={mailShow.product.thumbnail_alt}
                              width={150}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {mailShow.product.price ||
                            mailShow.product.price_per_month + "/เดือน"}
                        </TableCell>
                        <TableCell>{mailShow.product.price || mailShow.product.price_per_month}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}
