import { DataGrid } from "@mui/x-data-grid";
import { useState, Fragment } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";

import DateMoment from "../../../components/ui/date-moment/date-moment";

export default function OrderDataGrid({ orderData }) {
  const { t } = useTranslation("inbox-page");
  const columns = [
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "order_number",
      headerName: "เลขคำสั่งซื้อ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "firstname",
      headerName: "ชื่อ - สกุล",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <p>{param.row.firstname + " " + param.row.lastname}</p>
      ),
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "created_at",
      headerName: "วันเวลาสั่งซื้อ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <p>{<DateMoment format={"LLL"} date={param.row.created_at} />}</p>
        </>
      ),
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "phone_number",
      headerName: "เบอร์โทรติดต่อ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "email",
      headerName: "อีเมล์",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "order_status",
      headerName: "สถานะคำสั่งซื้อ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <p
          style={{
            color: param.row.order_status === "success" ? "#71be71" : "red",
          }}
        >
          {param.row.order_status === "success" ? "สำเร็จ" : "รอดำเนินการ"}
        </p>
      ),
    },
  ];

  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            disableRowSelectionOnClick
            rowHeight={50}
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={orderData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}
