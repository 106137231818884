import axios from "axios";

export const getMessages= ()=>{
    return axios.get(`messages/data`)
    .then((res) => res)
    .catch((err) => err);
}

export const svDeleteMessages = (id) =>{
    return axios.delete(`messages/delete/${id}`)
    .then((res) => res)
    .catch((err) => err);
}