import axios from "axios";

export const getMember =()=>{
    return axios
    .get(`member/data`)
    .then((res) => res)
    .catch((err) => err);
}

export const svUpdateMember = (formData) =>{
    return axios.post(`member/update`,formData)
    .then(function (response) {
        console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
}

export const svDeleteMember=(id)=>{
  return axios.delete(`member/destroy/${id}`)
  .then((res) => res)
  .catch((err) => err);
}