import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from "react-router-dom";
import { useEffect } from 'react';
import { guestGetWebinfo } from '../services/webinfo.service';
import { appActions } from '../store/app-slice';

const GuestRoutes = () => {
  const dispatch = useDispatch();
  const defaultURL = useSelector((state) => state.app.defaultURL)
  // const language = useSelector((state) => state.app.language)
  const uploadPath = useSelector((state) => state.app.uploadPath);

  useEffect(() => {
    const favicon = document.querySelector("link[rel='icon']");

    guestGetWebinfo().then(res => {
      // console.log(res.data.favicon.info_link);
      // return false;
      if (favicon) {
        favicon.href = uploadPath + res.data.favicon.info_link;
      }
      const logo = res.data.details.image_1.link
      dispatch(appActions.getWebInfoDetails({ logo }))
    });
  }, [])
  return (
    <div className="guest-main-page" >
      <div className="guest-image" style={{ "--bgimage": `url('${defaultURL}images/background.png')` }} >
        <figure className="guest-logo">
          <img src={defaultURL + "images/svg/logo-wynnsoft.svg"} />
          <p className="desc">วินน์ซอฟต์ โซลูชั่น ให้บริการด้านการจัดทำเว็บไซต์ ออกแบบเว็บไซต์ รับทำกราฟฟิก การตลาดออนไลน์ เว็บขายของออนไลน์</p>
        </figure>
        <figure className="guest-presenter">
          <img src={defaultURL + "images/presenter.png"} />
        </figure>
        <figure className="guest-btn">
          <img src={defaultURL + "images/button.png"} />
        </figure>
      </div>
      <div className="form-body">
        <Outlet />
      </div>
    </div>
  );
};

export default GuestRoutes;
