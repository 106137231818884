import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import HeadPageComponent from "../../../components/layout/headpage/headpage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faSitemap, faRedo } from "@fortawesome/free-solid-svg-icons";
import ButtonUI from "../../../components/ui/button/button";
import ContentFormatButton from "../../../components/ui/toggle-format/toggle-format";
import { TextField } from "@mui/material";
import { appActions } from "../../../store/app-slice";

import "./productcategory.scss";

import DataGridProductCate from "./gataGridproduct-category/DataGridProductCate";
import CategoryAddModal from "./product-category-modal/CategoryAddModal";
import CategoryEditModal from "./product-category-modal/CategoryEditModal";
import { svProducCate } from "../../../services/productServices/productcate.service";

function Category() {
  const { t } = useTranslation(["products-category-page"]);
  const language = useSelector((state) => state.app.language);
  const uPermission = useSelector((state) => state.auth.userPermission);
  const dispatch = useDispatch();
  const [spawnActive, setSpawnActive] = useState(true);
  const [refreshData, setRefreshData] = useState(0);
  const [isRowDisplay, setIsRowDisplay] = useState(true);
  const [textSearch, setTextSearch] = useState("");
  const [modalAddCate, setModalAddCate] = useState(false);
  const [modalEditCate, setModalEditCate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [productEdit, setProductEdit] = useState([]);
  const [maxPriority, setMaxPriority] = useState(0);

  useEffect(() => {
    setCategoryData([])
    dispatch(appActions.isSpawnActive(spawnActive));
    svProducCate(language).then((res) => {
      // return false;
      if (res.status === 200) {
        const result = res.data;
        // console.log(result);
        setCategoryData(result.cates);
        setFilteredData(result.cates);
        setMaxPriority(result.maxPriority);
      } else {
        console.log(res);
      }

      dispatch(appActions.isSpawnActive(false));
      setSpawnActive(false);
    });
  }, [refreshData, language]);

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  const searchTextData = (e) => {
    const text = e.target.value.toLowerCase();
    console.log(text);
    if (!text || text.trim() === "") {
      setFilteredData(categoryData);
    } else {
      const filtered = categoryData?.filter(
        (item) =>
          item &&
          (item.title.toString().toLowerCase().includes(text) ));
      setFilteredData(filtered);
    }
  };
  
  return (
    <section id="products-category-page">
      <HeadPageComponent
        h1={t("TitlePage")}
        icon={<FontAwesomeIcon icon={faSitemap} />}
        breadcrums={[{ title: t("TitlePage"), link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("Fetch")}
              </ButtonUI>
            </h2>

            <div className="action-right">
              <TextField
                // value={textSearch}
                label={t("search")}//"ค้นหา"
                id="outlined-size-small"
                size="small"
                onChange={(e) => searchTextData(e)}
              />
              {(uPermission.superAdmin || uPermission.admin)&&( <ButtonUI
                onClick={() => setModalAddCate(true)}
                className="btn-add-category"
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t("AddCate")}
              </ButtonUI>)}
             
            </div>
          </div>
        </div>
        {/* Tables Here */}
        <DataGridProductCate
          t={t}
          setRefreshData={setRefreshData}
          setOpenEdit={setModalEditCate}
          setProductEdit={setProductEdit}
          filteredData={filteredData}
          setIsEdit={setIsEdit}
         uPermission = {uPermission}
        />
        {/* Modal Add Here */}
        {modalAddCate && (
          <CategoryAddModal
            t={t}
            open={modalAddCate}
            setOpen={setModalAddCate}
            setRefreshData={setRefreshData}
            maxPriority={maxPriority}
          />
        )}
        {/* Modal Edit Here */}
        {modalEditCate && <CategoryEditModal 
          t={t}
          productEdit={productEdit}
          open={modalEditCate}
          setOpen={setModalEditCate}
          isEdit={isEdit}
          setRefreshData={setRefreshData}
        />}
      </div>
    </section>
  );
}

export default Category;
