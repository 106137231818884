import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import { Suspense } from "react";
import "./App.scss";

/* Component pages */
import ConfigPage from "./pages/config/config";
import DashboardPage from "./pages/dashboard/dashboard";
import LoginPage from "./pages/login/login";
import RegisterPage from "./pages/register/register";
import NotFoundPage from "./pages/page404";
import ShowUIComponentPage from "./pages/showui/showui.js";
import { useSelector } from "react-redux";
import GuestRoutes from "./utils/guestRoutes";
import LangConfigPage from "./pages/langconfig";
import AdminPage from "./pages/admin/admin";
import BounceLoading from "./components/ui/loading/bounce/bounce";
import SpawnLoading from "./components/ui/loading/spawn/spawn";
import WebInfoPage from "./pages/webinfo/webinfo";
import ProfileAdminPage from "./pages/admin/profile/profile";
import ForgetPasswordPage from "./pages/forgetpassword/forgetpassword";
import CategoryPage from "./pages/category/category";
import PostPage from "./pages/post/post";
import SlidePage from "./pages/slide/slide";
import MenuPage from "./pages/menu/menu";
import InboxPage from "./pages/inbox/inbox";
import ResetPasswordPage from "./pages/resetpassword/resetpassword";
import ContactMsPage from "./pages/contactMessage/ContactMsPage.jsx";
import ResearchPage from "./pages/research/ResearchPage.jsx";
import Personnel from "./pages/personnel/Personnel.jsx";
import ProductsCategory from "./pages/products/category/Category.jsx";
import ProductsAgriculture from "./pages/products/agriculture/Agriculture.jsx";
import MemberPage from "./pages/members/MemberPage.jsx";
import Messages from "./pages/messages/Messages.jsx";

function App() {
  const pagesAllow = useSelector((state) => state.app.pages);
  const isDevMode = useSelector((state) => state.app.isDevMode);
  const defaultURL = useSelector((state) => state.app.defaultURL)
  const uPermission = useSelector((state) => state.auth.userPermission);

  return (
    <Suspense>
      {/* Animetion loading */}
      <BounceLoading />
      <SpawnLoading />
      <Routes>
        <Route element={<PrivateRoutes defaultURL={defaultURL} />}>
          {uPermission.superAdmin || uPermission.admin ? (<Route path={defaultURL} element={<Navigate to={defaultURL + "category"} />} />):(<Route path={defaultURL} element={<Navigate to={defaultURL + "messages"} />} />)}
          {pagesAllow.dashboard && (
            <Route path={defaultURL + "dashboard"} element={<DashboardPage />} />
          )}
          {pagesAllow.inbox && <Route path={defaultURL + "inbox"} element={<InboxPage />} />}
          {pagesAllow.contactMessage && (
            <Route path={defaultURL + "contactmessage"} element={<ContactMsPage />} />
          )}

          {pagesAllow.slides && <Route path={defaultURL + "slides"} element={<SlidePage />} />}
          {pagesAllow.menu && <Route path={defaultURL + "menu"} element={<MenuPage />} />}
          {pagesAllow.category && (
            <Route path={defaultURL + "category"} element={<CategoryPage />} />
          )}

          {pagesAllow.posts && <Route path={defaultURL + "posts"} element={<PostPage />} />}
          {pagesAllow.personnels && <Route path={defaultURL + "personnels"} element={<Personnel />} />}
          {pagesAllow.research && <Route path={defaultURL + "research"} element={<ResearchPage />} />}

          {pagesAllow.productgroups.category && (<Route path={defaultURL + "ProductsCategories"} element={<ProductsCategory />} />)}
          {pagesAllow.productgroups.productpage && (<Route path={defaultURL + "ProductsAgriculture"} element={<ProductsAgriculture />} />)}

          {pagesAllow.messages && (<Route path={defaultURL + "messages"} element={<Messages />} />)}

          {pagesAllow.members && (<Route path={defaultURL + "Members"} element={<MemberPage />} />)}

          {/* {pagesAllow.reports && <Route path="reports" element={<DashboardPage />} /> } */}
          {pagesAllow.webinfo && (
            <Route path={defaultURL + "webinfo"} element={<WebInfoPage />} />
          )}
          {pagesAllow.languages && (
            <Route path={defaultURL + "languages"} element={<LangConfigPage />} />
          )}
          {pagesAllow.admins && <Route path={defaultURL + "admins"} element={<AdminPage />} />}
          {pagesAllow.configs && (
            <Route path={defaultURL + "configs"} element={<ConfigPage />} />
          )}
          {pagesAllow.profile && (
            <Route path={defaultURL + "profile"} element={<ProfileAdminPage />} />
          )}
          {isDevMode && (
            <Route path={defaultURL + "componentui"} element={<ShowUIComponentPage />} />
          )}
        </Route>
        <Route element={<GuestRoutes />}>
          <Route path={defaultURL + "login"} element={<LoginPage />} />
          <Route path={defaultURL + "register"} element={<RegisterPage />} />
          <Route path={defaultURL + "forgetpassword"} element={<ForgetPasswordPage />} />
          {/* <Route path={"forgetpassword"} element={<ForgetPasswordPage />} /> */}
          {/* <Route path={defaultURL + "resetpassword/:token"} element={<ResetPasswordPage />} /> */}
          <Route path={defaultURL + "setpassword/:token"} element={<ResetPasswordPage />} />
        </Route>
        <Route path="*" element={<Navigate to={defaultURL} />} />
      </Routes>
    </Suspense>
  );
}
export default App;
