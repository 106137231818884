import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faRedo } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormControlLabel, FormGroup, Modal, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { appActions } from "../../store/app-slice";
import ButtonUI from "../../components/ui/button/button";
import SwalUI from "../../components/ui/swal-ui/swal-ui";

import { svUpdateResearch } from "../../services/research.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

function ResearchEditModal({ open, setOpen, researchEdit, setRefreshData, isEdit}) {
  console.log(isEdit)
  const [isError, setIsError] = useState({
    title: false,
    researchers: false,
    year: false,
    link: false,
  });

  const { t } = useTranslation("research-page");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const [editData, setEditData] = useState(researchEdit);
  const [isFetching, setIsFetching] = useState(false);

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      title: editData.title.toString().trim().length < 1,
      researchers: editData.researchers.toString().trim().length < 1,
      year: editData.year.toString().trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      formData.append("id", editData.id);
      formData.append("title", editData.title);
      formData.append("details", editData.details);
      formData.append("description", editData.description || "");
      formData.append("year", editData.year);
      formData.append("researchers", editData.researchers);
      formData.append("link", editData.link || "");
      formData.append("pin", editData.pin?1:0);
      formData.append("display", editData.display?1:0);
      formData.append("language", isEdit ? editData.language : language);

      saveHandler(formData);
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svUpdateResearch(editData.id, formData).then((res) => {
      setOpen(false);
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="research-edit-modal">
          <section id="research-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} /> {t("แก้ไขงานวิจัย")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="research-details">
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              title: e.target.value,
                            };
                          })
                        }
                        inputProps={{ readOnly: false }}
                        value={editData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่องานวิจัย"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              details: e.target.value,
                            };
                          })
                        }
                        value={editData.details || ""}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-details"
                        label="รายละเอียด"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              description: e.target.value,
                            };
                          })
                        }
                        value={editData.description || ""}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-description"
                        label="คำอธิบาย"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              researchers: e.target.value,
                            };
                          })
                        }
                        value={editData.researchers}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.researchers}
                        id="ad-researchers"
                        label="ผู้ทำวิจัย"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        // placeholder="https://www.example.com/"
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              link: e.target.value,
                            };
                          })
                        }
                        value={editData.link}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.link}
                        id="ad-link"
                        label="ลิงก์ : https://www.example.com/"
                        size="small"
                        multiline
                      />
                    </div>
                    {/* <div className="input-half">
                      <DatePicker
                        className="year-input"
                        label={t("ปีที่ทำวิจัย")}
                        value={moment(editData.year).format("YYYY")}
                        openTo="year"
                        views={["year"]}
                        inputFormat="YYYY"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ width: "140px" }}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                              style: { textAlign: "left" },
                            }}
                          />
                        )}
                        onAccept={(value) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              year: moment(value).format("YYYY"),
                            };
                          })
                        }
                        onChange={() => true}
                      />
                    </div> */}
                    <div className="input-md-half">
                      <DatePicker
                        className="year-input"
                        value={moment(editData.year).format("YYYY")}
                        openTo="year"
                        views={["year"]}
                        inputFormat="YYYY"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ width: "140px" }}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                              style: { textAlign: "left" },
                              placeholder: t("ปีที่ทำวิจัย"),
                              label: t("ปีที่ทำวิจัย")
                            }}
                          />
                        )}
                        onAccept={(value) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              year: moment(value).format("YYYY"),
                            };
                          })
                        }
                        onChange={() => true}
                      />
                    </div>
                    {!isEdit && (
                      <div className="setting-controls">
                        <div className="switch-form">
                        <FormGroup>
                          <FormControlLabel  control={<Switch onChange={(e) => setEditData({...editData, display: e.target.checked})} checked={editData.display?true:false} />} label={t("การแสดงผล")} labelPlacement="start" />
                        </FormGroup>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel  control={<Switch onChange={(e) => setEditData({...editData, pin: e.target.checked})} checked={editData.pin?true:false} /> }  label={t("Pin")}  labelPlacement="start" />
                          </FormGroup>
                        </div>
                      </div>
                    )}
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  isLoading={isFetching}
                  onClick={editValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </LocalizationProvider>
  );
}

export default ResearchEditModal;
