import { faRedo, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { appActions } from "../../store/app-slice";
// import ContactMsTab from "./ContactMsTab";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import { FormControl, InputLabel, Input, TextField } from "@mui/material";
import ButtonUI from "../../components/ui/button/button";
import { svMailAll } from "../../services/inbox.service";

import "./contactms.scss";
import ContactMsTab from "./contactms-tab";

const ContactMsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["contactms-page"]);
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [tabSelect, setTabSelect] = useState("0");
  const [searchMs, setSearchMs] = useState("");
  const [spawnActive, setSpawnActive] = useState(true);
  const [contactMsData, setContactMsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const filterData = (contactMs) => {
    if (contactMs.length > 0) {
      if (tabSelect === "0") {
        setFilteredData(contactMs);
        return;
      } else
      if (tabSelect === "3") {
        const ms = contactMs?.filter((m) => m.pin);
        setFilteredData(ms);
        return;
      }
      const filtered = contactMs?.filter((m) => m.type_id === parseInt(tabSelect));
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  };

  useEffect(() => {
    filterData(contactMsData);
  }, [tabSelect]);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svMailAll().then((res) => {
      if (res.status === 200) {
        const data = res.data;
        const unreadData = res.data.unreadData;
        
        dispatch(appActions.getMailInbox(unreadData))
        setContactMsData(data.contactMs);
        filterData(data.contactMs);
      } else {
        console.error(res);
        dispatch(appActions.getMailInbox({mails: [], contactMs: []}))
      }
      dispatch(appActions.isSpawnActive(false));
      setSpawnActive(false);
    });
  }, [refreshData, language]);

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  const searchData = async (e) => {
    setSearchMs(e.target.value);
    const text = e.target.value.toLowerCase();
    if (!text || text.trim() === "") {
      setFilteredData(contactMsData);
    } else {

      let filtered = contactMsData;
      if (tabSelect === "3") {
        filtered = contactMsData?.filter((m) => m.pin);
      } 

      const ms = filtered?.filter(
        (item) =>
          item &&
          (item.firstname.toLowerCase().includes(text) ||
            item.lastname.toLowerCase().includes(text) ||
            item.phone_number.toLowerCase().includes(text) ||
            item.email.toLowerCase().includes(text))
      );
      setFilteredData(ms);
    }
  };

  return (
    <section id="contactms-page">
      <HeadPageComponent
        h1={"ข้อความติดต่อ"}
        icon={<FontAwesomeIcon icon={faEnvelope} />}
        breadcrums={[{ title: "ข้อความติดต่อ", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <ButtonUI
              onClick={refreshHandle}
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faRedo} />}
            >
              {t("ดึงข้อมูล")}
            </ButtonUI>
            <div className="action-right">
              <TextField
                value={searchMs}
                label="ค้นหา"
                id="outlined-size-small"
                size="small"
                onChange={(e) => searchData(e)}
              />
            </div>
          </div>
        </div>

        <ContactMsTab
          filteredData={filteredData}
          filterData={filterData}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          tabSelect={tabSelect}
          setTabSelect={setTabSelect}
          setFilteredData={setFilteredData}
        />
      </div>
    </section>
  );
};

export default ContactMsPage;
