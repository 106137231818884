import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faRedo, faBookmark } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@mui/material";

import HeadPageComponent from "../../components/layout/headpage/headpage";
import ButtonUI from "../../components/ui/button/button";
import { appActions } from "../../store/app-slice";

import DataGridResearch from "./datagrid/DataGridResearch";
import ResearchEditModal from "./ResearchEditModal";
import ResearchAddModal from "./ResearchAddModal";
import { svGetResearch } from "../../services/research.service";

import "./research.scss";

export default function ResearchPage() {
  const { t } = useTranslation("research-page");
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [researchEdit, setResearchEdit] = useState([]);
  const [spawnActive, setSpawnActive] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();

  const [researchData, setResearchData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setResearchData([]);
    setFilteredData([]);
    dispatch(appActions.isSpawnActive(true));
    svGetResearch(language).then((res) => {
      if (res.status === 200) {
        const result = res.data;
        setResearchData(result.research);
        setFilteredData(result.research);
      } else {
        console.log(res);
      }

      setSpawnActive(false);
      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData, language]);

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  const searchTextData = (e) => {
    const text = e.target.value.toLowerCase();
    if (!text || text.trim() === "") {
      setFilteredData(researchData);
    } else {
      const filtered = researchData?.filter(
        (item) =>
          item &&
          (item.title.toString().toLowerCase().includes(text) ||
            item.researchers.toLowerCase().includes(text) ||
            item.year.toString().toLowerCase().includes(text))
      );
      setFilteredData(filtered);
    }
  };

  const berluckyAddHandle = () => {
    setOpenModalAdd(true);
  };

  return (
    <section id="research-page">
      <HeadPageComponent
        h1={t("งานวิจัย")}
        icon={<FontAwesomeIcon icon={faBookmark} />}
        breadcrums={[{ title: t("งานวิจัย"), link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <div className="action-right">
              <TextField
                // value={searchInput}
                label="ค้นหา"
                id="outlined-size-small"
                size="small"
                onChange={(e) => searchTextData(e)}
              />
              <ButtonUI
                onClick={berluckyAddHandle}
                style={{ width: "120px" }}
                className="btn-add-research"
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t("เพิ่มงานวิจัย")}
              </ButtonUI>
            </div>
            {/* <TextField
              label="ค้นหา"
              id="outlined-size-small"
              size="small"
              onInput={(e) => searchTextData(e)}
            /> */}
          </div>
        </div>

        {/* Research Table Here */}
        <DataGridResearch
          setRefreshData={setRefreshData}
          filteredData={filteredData}
          setOpenEdit={setOpenEdit}
          setIsEdit={setIsEdit}
          setResearchEdit={setResearchEdit}
          setFilteredData={setFilteredData}
          setResearchData={setResearchData}
          language={language}
        />

        {/* Research Modal Edit Here */}
        {openEdit && (
          <ResearchEditModal
            setRefreshData={setRefreshData}
            researchEdit={researchEdit}
            open={openEdit}
            isEdit={isEdit}
            setOpen={setOpenEdit}
            language={language}
          />
        )}

        {openModalAdd && (
          <ResearchAddModal
            setRefreshData={setRefreshData}
            open={openModalAdd}
            setOpen={setOpenModalAdd}
            language={language}
          />
        )}
      </div>
    </section>
  );
}
