import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faRedo, faUsersGear } from "@fortawesome/free-solid-svg-icons";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ButtonUI from "../../components/ui/button/button";
import { appActions } from "../../store/app-slice";

import "./member.scss";
import { getMember } from "../../services/member.Service";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import DataGridMember from "./dataGrid/MemberTables";
import MemberModalEdit from "./modal/MemberModalEdit";

function MemberPage() {
  const { t } = useTranslation("member-page");
  const language = useSelector((state) => state.app.language);
  const [spawnActive, setSpawnActive] = useState(true);
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [pageControl, setPageControl] = useState(0);
  const [memberData, setMemberData] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);
  const [memberEdit, setMemberEdit] = useState([]);

  useEffect(() => {
    getMember().then((res) => {
      // console.log(res);
      // return false;
      if (res.status === 200) {
        const result = res.data;
        setMemberData(result.member);
        // setFilteredData(result.member);
      } else {
        console.log(res);
      }
      setSpawnActive(false);
      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData, language]);

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  const OnChangePageControlHandler = (e) => {
    setPageControl(e.target.value);
  };


  return (
    <section id="member-page">
      <HeadPageComponent
        h1={t("memberHeadPage")}
        icon={<FontAwesomeIcon icon={faUsersGear} />}
        breadcrums={[{ title: t("memberHeadPage"), link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("Fetch")}
              </ButtonUI>
            </h2>
            <FormControl className="searchpage" variant="standard">
              <InputLabel id="filter-pgae">{t("SelectPageControl")}</InputLabel>
              
              <Select
                labelId="post-pgae"
                autoWidth
                id="filter-page"
                label={t("SelectPageControl")}
                className="input-page"
                size="small"
                onChange={OnChangePageControlHandler}
                value={pageControl}
              >
                <MenuItem value={0}>
                  {" "}
                  {t("allMember")}{" "}
                </MenuItem>
                <MenuItem value={1}>
                  {" "}
                  {t("activeMember")}{" "}
                </MenuItem>
                <MenuItem value={2}>
                  {" "}
                  {t("inactiveMember")}{" "}
                </MenuItem>
                <MenuItem value={3}>
                  {" "}
                  {t("expireMember")}{" "}
                </MenuItem>
              </Select>
            </FormControl>
              <h2 className="head-title">
              <FontAwesomeIcon icon={faCircle}  style={{color: "#FCDC2A",}} />{" "}{t('alertcolorY')}
              </h2>
              <h2>
              <FontAwesomeIcon icon={faCircle} style={{color: "#FF204E",}} />{" "}{t('alertcolorR')}
              </h2>
            <div className="action-right">
              <TextField
                label={t("search")}
                value={textSearch}
                id="outlined-size-small"
                size="small"
                onChange={(e) => setTextSearch(e.target.value)}
              />
            </div>
          </div>

        </div>
        {/* Tables Here */}
        <DataGridMember
          t={t}
          setRefreshData={setRefreshData}
          setOpenEdit={setOpenEdit}
          setMemberEdit={setMemberEdit}
          memberData={memberData}
          textSearch={textSearch}
          pageControl={pageControl}
          // setIsEdit={setIsEdit}
        />
        {/* Modal Edit Here */}
        {openEdit && (
          <MemberModalEdit
            t={t}
            memberEdit={memberEdit}
            open={openEdit}
            setOpen={setOpenEdit}
            // isEdit={isEdit}
            setRefreshData={setRefreshData}
          />
        )}
      </div>
    </section>
  );
}

export default MemberPage;
