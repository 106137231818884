import { faInbox, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { appActions } from "../../store/app-slice";
import InboxTab from "./inbox-tab";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import { FormControl, InputLabel, Input, TextField } from "@mui/material";
import ButtonUI from "../../components/ui/button/button";
import { svMailAll } from "../../services/inbox.service";

import "./inbox.scss";

const InboxPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["inbox-page"]);
  const language = useSelector((state) => state.app.language);
  const [refreshData, setRefreshData] = useState(0);
  const [tabSelect, setTabSelect] = useState("0");
  const [searchInbox, setSearchInbox] = useState("");
  const [spawnActive, setSpawnActive] = useState(true);
  const [mailData, setMailData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const filterData = (mails) => {
    if (mails.length > 0) {
      if (tabSelect === "0") {
        setFilteredData(mails);
        return;
      } else
      if (tabSelect === "3") {
        const mail = mails?.filter((m) => m.pin);
        setFilteredData(mail);
        return;
      }
      const filtered = mails?.filter((m) => m.type_id === parseInt(tabSelect) && m.type_id !== 0);
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  };

  useEffect(() => {
    filterData(mailData);
  }, [tabSelect]);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(spawnActive));
    svMailAll().then((res) => {
      if (res.status === 200) {
        const data = res.data;
        const unreadData = res.data.unreadData;
        
        dispatch(appActions.getMailInbox(unreadData))
        setMailData(data.mails);
        filterData(data.mails);
      } else {
        dispatch(appActions.getMailInbox({mails: [], contactMs: []}))
      }
      dispatch(appActions.isSpawnActive(false));
      setSpawnActive(false);
    });
  }, [refreshData, language]);

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  const searchData = async (e) => {
    setSearchInbox(e.target.value);
    const text = e.target.value.toLowerCase();
    if (!text || text.trim() === "") {
      setFilteredData(mailData);
    } else {

      let filtered = mailData;
      if (tabSelect === "3") {
        filtered = mailData?.filter((m) => m.pin);
      } else
      if (tabSelect !== "0") {
        filtered = mailData?.filter((m) => m.type_id === parseInt(tabSelect));
      }

      const mail = filtered?.filter(
        (item) =>
          item &&
          (item.firstname.includes(text) ||
            item.lastname.includes(text) ||
            item.phone_number.includes(text) ||
            item.email.includes(text) ||
            item.address.includes(text))
      );
      setFilteredData(mail);
    }
  };

  return (
    <section id="inbox-page">
      <HeadPageComponent
        h1={"กล่องข้อความ"}
        icon={<FontAwesomeIcon icon={faInbox} />}
        breadcrums={[{ title: "กล่องข้อความ", link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <ButtonUI
              onClick={refreshHandle}
              on="create"
              isLoading={false}
              icon={<FontAwesomeIcon icon={faRedo} />}
            >
              {t("ดึงข้อมูล")}
            </ButtonUI>
            <div className="action-right">
              <TextField
                value={searchInbox}
                label="ค้นหา"
                id="outlined-size-small"
                size="small"
                onChange={(e) => searchData(e)}
              />
            </div>
          </div>
        </div>

        <InboxTab
          filteredData={filteredData}
          filterData={filterData}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          tabSelect={tabSelect}
          setTabSelect={setTabSelect}
          setFilteredData={setFilteredData}
        />
      </div>
    </section>
  );
};

export default InboxPage;
