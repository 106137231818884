const appConfigDEV = {
  isDevMode: false,
  language: "th",
  timeZone: "Asia/Bangkok",
  languageAvailable: ["th", "en"],
  apiPath: "http://localhost:8000/api/backoffice/v1/",
  uploadPath: "http://localhost:8000/",
  webPath: "http://localhost:8000/",
  defaultURL: "/",
  // apiPath:"http://192.168.1.51:8000/api/backoffice/v1/",
  // uploadPath:"http://192.168.1.51:8000/",
  // webPath:"http://192.168.1.51:8000/",
  pages: {
    dashboard: false,
    messages: true,
    contactMessage: false,
    inbox: false,
    orders: false,
    shipping: false,
    subscribe: false,
    productcate: false,
    members: true,
    slides: true,
    menu: false,
    category: true,
    posts: true,
    research: false,
    reports: false,
    webinfo: true,
    languages: true,
    admins: true,
    configs: true,
    profile: false,
    groups: {
      notify: true,
      article: true,
      products: false,
      prediction: false,
      report: false,
      system: true,
    },
    productgroups: {
      categories:true,
      category:true,
      productpage:true,
      /*
      berlucky: false,
      berluckycate: false,
      fiber: false,
      fibercate: false,
      package: false,
      packagecate: false,
      move: false,
      movecate: false,
      travel: false,
      travelcate: false,
      */
    },
  },
  features: {
    multilingual: true,
    flexibleCategory: true,
    multipleImage: true,
    seo: true,
    price: false,
    tag: true,
    iframe: true,
    redirect: true,
    social: false,
    notify: true,
  },
};

const appConfigPROD = {
  isDevMode: false,
  language: "th",
  defaultLanguage: "th",
  timeZone: "Asia/Bangkok",
  languageAvailable: ["th", "en"],
  apiPath: "https://agrostarseedsgroup.com/api/backoffice/v1/",
  uploadPath: "https://agrostarseedsgroup.com/",
  webPath: "https://agrostarseedsgroup.com/",
  defaultURL: "/",
  // defaultURL: "/backoffice/",
  // webPath: "https://juppgas-delivery.shop/",
  pages: {
    dashboard: false,
    messages: true,
    contactMessage: false,
    inbox: false,
    orders: false,
    shipping: false,
    subscribe: false,
    productcate: false,
    members: true,
    slides: true,
    menu: false,
    category: true,
    posts: true,
    research: false,
    reports: false,
    webinfo: true,
    languages: true,
    admins: true,
    configs: true,
    profile: false,
    groups: {
      notify: true,
      article: true,
      products: false,
      prediction: false,
      report: false,
      system: true,
    },
    productgroups: {
      categories:true,
      category:true,
      productpage:true,
      /*
      berlucky: false,
      berluckycate: false,
      fiber: false,
      fibercate: false,
      package: false,
      packagecate: false,
      move: false,
      movecate: false,
      travel: false,
      travelcate: false,
      */
    },
  },
  features: {
    multilingual: true,
    flexibleCategory: true,
    multipleImage: true,
    seo: true,
    price: false,
    tag: true,
    iframe: true,
    redirect: true,
    social: false,
    notify: true,
  },
};

// export default appConfigDEV;
export default appConfigPROD;
