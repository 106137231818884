import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faRedo,
  faUsersGear,
  faBoxArchive,
} from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@mui/material";
import ButtonUI from "../../components/ui/button/button";
import { appActions } from "../../store/app-slice";

import "./messages.scss";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import { getMessages } from "../../services/messages.service";
import MessagesContactTable from "./dataGrid/MessagesContactTable";
import MessagesContactRead from "./modal/MessagesContactRead";

function Messages() {
  const { t } = useTranslation("messages-page");
  const language = useSelector((state) => state.app.language);
  const uPermission = useSelector((state) => state.auth.userPermission);
  const [spawnActive, setSpawnActive] = useState(true);
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(0);
  //   const [openEdit, setOpenEdit] = useState(false);
  const [messagesData, setMessagesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [messagesRead, setMessagesRead] = useState([]);
  const [open, setOpen] = useState(false);
  // const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(appActions.isSpawnActive(true));
    getMessages().then((res) => {
      if (res.status === 200) {
        const result = res.data;
        // console.log(result);
        setMessagesData(result.messageall);
        setFilteredData(result.messageall);
      } else {
        console.log(res);
      }
      setSpawnActive(false);
      dispatch(appActions.isSpawnActive(false));
    });
  }, [refreshData, language]);

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  const searchTextData = (e) => {
    const text = e.target.value.toLowerCase();
    console.log(text);
    if (!text || text.trim() === "") {
      setFilteredData(messagesData);
    } else {
      const filtered = messagesData?.filter(
        (item) =>
          item &&
          (item.topic.toString().toLowerCase().includes(text) ||
            item.fullname.toLowerCase().includes(text) ||
            item.email.toString().toLowerCase().includes(text))
      );
      setFilteredData(filtered);
    }
  };
  // console.log(messagesRead);
  return (
    <section id="messages-contact-page">
      <HeadPageComponent
        h1={t("messgarsH1")}
        icon={<FontAwesomeIcon icon={faBoxArchive} />}
        breadcrums={[{ title: t("messgarsH1"), link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-titile">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("Fetch")}
              </ButtonUI>
            </h2>
            <div className="action-right">
              <TextField
                // value={searchInput}
                label={t("search")}
                id="outlined-size-small"
                size="small"
                onChange={(e) => searchTextData(e)}
              />
            </div>
          </div>
        </div>
        {/* messages contact Table Here */}
        <MessagesContactTable
          t={t}
          uPermission={uPermission}
          setRefreshData={setRefreshData}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          setMessagesRead={setMessagesRead}
          setMessagesData={setMessagesData}
          setOpen={setOpen}
        />

        {/* messages contact Modal Read Here */}
        <MessagesContactRead
          t={t}
          setRefreshData={setRefreshData}
          messagesData={messagesData}
          read={messagesRead}
          open={open}
          setOpen={setOpen}
        />
      </div>
    </section>
  );
}

export default Messages;
