import axios from "axios";

/* Research */
export const svGetResearch = (language) => {
return axios
.get(`researchall?language=${language}`)
.then((res) => res)
.catch((err) => err);
};

export const svCreateResearch = (formData) => {
  return axios
    .post(`createresearch`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdateResearch = (id, formData) => {
  return axios
    .post(`updateresearch/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svDeleteResearch = (id, language) => {
  return axios
    .delete(`deleteresearch/${id}/${language}`)
    .then((res) => res)
    .catch((err) => err);
};