import { DataGrid } from "@mui/x-data-grid";
import { Fragment } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";

import DateMoment from "../../../components/ui/date-moment/date-moment";

export default function MailServiceDataGrid({ mailServiceData }) {
  const columns = [
    {
      flex: 1,
      field: "type_id",
      headerName: "ประเภท",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <p style={{ fontWeight: param.row.readed ? "normal" : "bold" }}>
            {param.row.type_id === 1 ? "อินเตอร์เน็ตไฟเบอร์" : "ย้ายค่าย"}
          </p>
        </>
      ),
    },
    {
      flex: 1,
      field: "created_at",
      headerName: "วันเวลาส่ง",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <p>{<DateMoment format={"LLL"} date={param.row.created_at} />}</p>
        </>
      ),
    },
    {
      flex: 1,
      field: "firstname",
      headerName: "ชื่อ - สกุล",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <p>{param.row.firstname + " " + (param.row.lastname ? param.row.lastname : "")}</p>
      ),
    },
    {
      flex: 1,
      field: "phone_number",
      headerName: "เบอร์โทร",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      flex: 1,
      field: "email",
      headerName: "อีเมล์",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      flex: 1,
      field: "line_id",
      headerName: "ไลน์ไอดี",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
  ];

  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            disableRowSelectionOnClick
            rowHeight={50}
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={mailServiceData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}
