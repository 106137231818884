import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HeadPageComponent from "../../../components/layout/headpage/headpage";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import "./agriculture.scss";
import { faSeedling, faRedo, faAdd } from "@fortawesome/free-solid-svg-icons";
import ButtonUI from "../../../components/ui/button/button";
import ContentFormatButton from "../../../components/ui/toggle-format/toggle-format";
import { getCategoryList } from "../../../services/productServices/pageagriculture.service";
import { getProduct } from "../../../services/productServices/pageagriculture.service";
import { appActions } from "../../../store/app-slice";
import AgricultureTab from "./tab/AgricultureTab";
import AgricultureAddModal from "./modal/AgricultureAddModal";

function Agriculture() {
  const dispatch = useDispatch();
  const { t } = useTranslation(["product-page"]);
  const language = useSelector((state) => state.app.language);
  const uPermission = useSelector((state) => state.auth.userPermission);

  const [spawnActive, setSpawnActive] = useState(true);
  const [refreshData, setRefreshData] = useState(0);
  const [isRowDisplay, setIsRowDisplay] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [pageControl, setPageControl] = useState("");
  const [agricultureData, setAgricultureData] = useState([]);
  const [agricultureTab, setAgricultureTab] = useState("0");
  const [textSearch, setTextSearch] = useState("");
  const [agriModalAdd, setAgriModalAdd] = useState(false);
  const [maxPriority, setMaxPriority] = useState(0);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(true));
    getCategoryList(language)
      .then(function (res) {
        // handle success
        if (res.status === 200) {
          setCategoryList(res.data.cates);
        }
        dispatch(appActions.isSpawnActive(false));
        setSpawnActive(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        dispatch(appActions.isSpawnActive(false));
        setSpawnActive(false);
      });
  }, [language]);

  useEffect(() => {
    setAgricultureData([]);
    dispatch(appActions.isSpawnActive(true));
    getProduct(language)
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
            const result = res.data;
          setAgricultureData(result.product);
          setMaxPriority(result.maxPriority);
        }
        dispatch(appActions.isSpawnActive(false));
        setSpawnActive(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(appActions.isSpawnActive(false));
        setSpawnActive(false);
      });
  }, [refreshData, language]);

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };
  const OnChangePageControlHandler = (e) => {
    setPageControl(e.target.value);
  };
  // console.log(maxPriority);
  return (
    <section id="product-page">
      <HeadPageComponent
        h1={t("ProductAgri")}
        icon={<FontAwesomeIcon icon={faSeedling} />}
        breadcrums={[{ title: t("ProductAgri"), link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("Fetch")}
              </ButtonUI>
            </h2>
            <ContentFormatButton
              isRowDisplay={isRowDisplay}
              setIsRowDisplay={setIsRowDisplay}
            />
            <FormControl className="searchpage" variant="standard">
              <InputLabel id="filter-pgae">{t("SelectPageControl")}</InputLabel>
              <Select
                labelId="post-pgae"
                autoWidth
                id="filter-page"
                label={t("SelectPageControl")}
                className="input-page"
                size="small"
                onChange={OnChangePageControlHandler}
                value={pageControl}
              >
                <MenuItem value="">{t("SelectPageControlNone")}</MenuItem>
                {categoryList &&
                  categoryList.map((cates) => (
                    <MenuItem key={cates.id} value={cates.id}>
                      {cates.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div className="action-right">
              <TextField
                value={textSearch}
                label={t("Search")}
                id="outlined-size-small"
                size="small"
                onChange={(e) => setTextSearch(e.target.value)}
              />
              {(uPermission.superAdmin||uPermission.admin)&&(
                <ButtonUI
                onClick={() => setAgriModalAdd(true)}
                className="btn-add-post"
                on="create"
                icon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t("AgriAdd")}
              </ButtonUI>
              )}
              
            </div>
          </div>
        </div>
        {/* Tab Here */}
        <AgricultureTab 
          t={t}
          setRefreshData={() => setRefreshData(refreshData + 1)}
          pageControl={pageControl}
          category={categoryList}
          setCategory={setCategoryList}
          agricultureTab={agricultureTab}
          setAgricultureTab={setAgricultureTab}
          agricultureData={agricultureData}
          isRowDisplay={isRowDisplay}
          textSearch={textSearch}
        />

        {/* Modal Add Here */}
        {agriModalAdd && (
          <AgricultureAddModal
            t={t}
            category={categoryList}
            isOpen={agriModalAdd}
            setClose={setAgriModalAdd}
            setRefreshData={setRefreshData}
            maxPriority={maxPriority}
          />
        )}
      </div>
    </section>
  );
}

export default Agriculture;
