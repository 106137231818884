import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faMinus,
  faPenToSquare,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormControlLabel, FormGroup, Modal, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import PreviewImageUI from "../../../components/ui/preview-image/preview-image";
import FieldsetUI from "../../../components/ui/fieldset/fieldset";
import ButtonUI from "../../../components/ui/button/button";
import { svUpdatePersonnel } from "../../../services/personnel.service";
import "./personnel-modal.scss";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";

import { appActions } from "../../../store/app-slice";

function PersonnelEditModal({ isOpen, setClose, setRefreshData, items, isEdit }) {
  const { t } = useTranslation("personnel-page");
  const [previews, setPreviews] = useState({ src: "", file: null, name: null });
  const [isError, setIsError] = useState({
    profile_image: false,
    name: false,
    position: false,
    is_manager: false,
  });

  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const uploadPath = useSelector((state) => state.app.uploadPath);
  const [editData, setEditData] = useState(items);
  const [isFetching, setIsFetching] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    /* Set thumbnail */
    let thumbnail = uploadPath + items.profile_image;
    setPreviews({
      file: null,
      src: thumbnail,
      remove: true,
      srcDefault: thumbnail,
    });
  }, []);

  const setPreviewHandler = (data) => {
    setEditData({ ...editData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority + 1 };
      });
    } else if (editData.priority > 1) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority - 1 };
      });
    }
  };

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      name: editData.name.trim().length < 1,
      position: editData.position.trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("profile_image", previews.file);
      }
      formData.append("id", editData.id);
      formData.append("old_image", editData.profile_image);
      formData.append("name", editData.name || "");
      formData.append("position", editData.position);
      formData.append("email", editData.email || "");
      formData.append("expertise", editData.expertise || "");
      formData.append("display", editData.display ? 1 : 0);
      formData.append("is_manager", editData.is_manager ? 1 : 0);
      formData.append("priority", editData.priority);
      formData.append("language", isEdit ? editData.language : language);

      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svUpdatePersonnel(editData.id, formData).then((res) => {
      setClose({ isEdit: false, isOpen: false });
      setIsFetching(false);

      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={isOpen}
        onClose={() =>
          setClose({
            isEdit: false,
            isOpen: false,
          })
        }
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="personnel-edit-modal">
          <section id="personnel-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faPenToSquare} /> {t("แก้ไขโปรไฟล์")}
                  </h2>
                </div>
              </div>
              <div className="card-body" ref={scrollRef}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <FieldsetUI
                    className={`image-setting ${
                      isError.profile_image ? "error" : ""
                    }`}
                    label={t("รูปภาพโปรไฟล์")}
                  >
                    <PreviewImageUI
                      className="add-image"
                      previews={previews}
                      setPreviews={setPreviewHandler}
                    />
                  </FieldsetUI>

                  <div className="personnel-details">
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              name: e.target.value,
                            };
                          })
                        }
                        value={editData.name}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.name}
                        id="ad-name"
                        label="ชื่อ"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              position: e.target.value,
                            };
                          })
                        }
                        value={editData.position}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.position}
                        id="ad-position"
                        label="ตำแหน่ง"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              email: e.target.value,
                            };
                          })
                        }
                        value={editData.email}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-email"
                        label="อีเมล์"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              expertise: e.target.value,
                            };
                          })
                        }
                        value={editData.expertise}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-expertise"
                        label="ความเชี่ยวชาญ"
                        size="small"
                        multiline
                      />
                    </div>

                    <div className="group-settings">
                      <div className="setting-controls">
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setEditData({
                                      ...editData,
                                      is_manager: e.target.checked,
                                    })
                                  }
                                  checked={editData.is_manager ? true : false}
                                />
                              }
                              label={t("หัวหน้าสาขา")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setEditData({
                                      ...editData,
                                      display: e.target.checked,
                                    })
                                  }
                                  checked={editData.display ? true : false}
                                />
                              }
                              label={t("การแสดงผล")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="input-group-settings">
                          <div className="inp">
                            <ButtonUI
                              color="error"
                              onClick={(e) => priorityHandler(false)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </ButtonUI>
                            <span className="title">
                              {t("ลำดับ")} {editData.priority}
                            </span>
                            <ButtonUI onClick={(e) => priorityHandler(true)}>
                              <FontAwesomeIcon icon={faAdd} />
                            </ButtonUI>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  isLoading={isFetching}
                  onClick={addValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() =>
                    setClose({
                      isEdit: false,
                      isOpen: false,
                    })
                  }
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}

export default PersonnelEditModal;
