import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Checkbox, Modal, Switch, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, Fragment } from "react";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

import DateMoment from "../../../components/ui/date-moment/date-moment";
import ButtonUI from "../../../components/ui/button/button";
import { StarRounded } from "@mui/icons-material";
import { svDeleteMail, svUpdateReaded } from "../../../services/inbox.service";

const modalSwal = withReactContent(Swal);

export default function DataGridInbox({
  setFilteredData,
  filterData,
  filteredData,
  setOpenModal,
  setMailShow,
  setRefreshData,
}) {
  const { t } = useTranslation("inbox-page");
  const columns = [
    {
      //   width: 150,
      flex: 1 / 3,
      field: "",
      headerName: "",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Checkbox
            onChange={(e) => updatePin(e.target.checked, param.row.id)}
            defaultChecked={param.row.pin ? true : false}
            icon={<StarRounded />}
            checkedIcon={<StarRounded />}
            color="warning"
          />
        </>
      ),
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "type_id",
      headerName: "ประเภท",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <p style={{ fontWeight: param.row.readed ? "normal" : "bold" }}>
            {param.row.type_id === 1 ? "อินเตอร์เน็ตไฟเบอร์" : "ย้ายค่าย"}
          </p>
        </>
      ),
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "created_at",
      headerName: "วันเวลาส่ง",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <p>{<DateMoment format={"LLL"} date={param.row.created_at} />}</p>
        </>
      ),
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "firstname",
      headerName: "ชื่อ - สกุล",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <p>{param.row.firstname + " " + param.row.lastname}</p>
      ),
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "phone_number",
      headerName: "เบอร์โทร",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "email",
      headerName: "อีเมล์",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "line_id",
      headerName: "ไลน์ไอดี",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 90,
      flex: 1,
      field: "action",
      headerName: "จัดการ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {"th" === "th" ? (
            <div className="box-action">
              <ButtonUI
                onClick={() => showMailHandle(params.row)}
                on="cancel"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEye} />}
                width={"xxs"}
              >
                {t("")}
              </ButtonUI>
              <ButtonUI
                onClick={() => deleteHandle(params.row)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
              >
                {t("")}
              </ButtonUI>
            </div>
          ) : (
            <ButtonUI
              onClick={() => console.log("on add anothor language")}
              on="add"
              className="btn-custom onAdd"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t("Add")}
            </ButtonUI>
          )}
        </>
      ),
    },
  ];

  const updatePin = (checked, _id) => {
    axios
      .patch(`mail/updatepin/${_id}`, { pin: checked ? 1 : 0 })
      .then((res) => {
        if (res.status === 200) {
          const updated = filteredData?.map((m) => {
            if (m.id === _id) {
              m.pin = checked ? 1 : 0;
            }
            return m;
          });
          setFilteredData(updated);
          filterData(updated);
        } else {
          console.log(res);
        }
      });
  };

  const showMailHandle = (row) => {
    setMailShow(row);
    setOpenModal(true);
    if (!row.readed) {
      svUpdateReaded(row.id).then(res => {
        console.log(res)
        if (res.status === 200) {
          setRefreshData((prev) => prev + 1);
        }
      })
    }
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeleteMail(row.id).then((res) => {
            if (res.status === 200) {
              modalSwal
                .fire({
                  position: "center",
                  width: 450,
                  icon: "success",
                  title: "Successful",
                  text: res.data.description,
                  showConfirmButton: false,
                  timer: 2000,
                })
                .then(() => {
                  setRefreshData((prev) => prev + 1);
                });
            } else {
              modalSwal.fire({
                position: "center",
                width: 450,
                icon: "error",
                title: "Failed",
                text: res.data.description,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
        }
      });
  };

  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            // getRowHeight={() => 'auto'} // set row height auto
            rowHeight={75}
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}
