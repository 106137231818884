import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContentCardUI from "../../../../components/ui/content-card/content-card";
import AgricultureEditMpdal from "../modal/AgricultureEditMpdal";

import {
  faEyeSlash,
  faFolderOpen,
  faMapPin,
  faLanguage,
  faLink,
  faArrowDownShortWide,
  faPenNib,
  faStopwatch,
  faStar,
  faHeart,
  faYinYang,
  faBook,
  faUserGraduate,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faNewspaper } from "@fortawesome/free-regular-svg-icons";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TablePagination } from "@mui/material";
import { svDeleteProduc } from "../../../../services/productServices/pageagriculture.service";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SwalUI from "../../../../components/ui/swal-ui/swal-ui";
const modalSwal = withReactContent(Swal);

function AgricultureTab(props) {
  const {
    t,
    setRefreshData,
    pageControl,
    category,
    setCategory,
    agricultureTab,
    setAgricultureTab,
    agricultureData,
    isRowDisplay,
    textSearch,
  } = props;

  const isSuerperAdmin = useSelector(
    (state) => state.auth.userPermission.superAdmin
  );

  const webPath = useSelector((state) => state.app.webPath);
  const [totalData, setTotalData] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [limited, setLimited] = useState({ begin: 0, end: rowsPerPage });
  const [filteredData, setFilteredData] = useState([]);
  const [items, setItems] = useState(null);
  const [productModalEdit, setProductModalEdit] = useState({
    isEdit: false,
    isOpen: false,
  });

  const tabLists = [
    {
      value: "0",
      title: t("All Product"),
      icon: <FontAwesomeIcon icon={faFolderOpen} />,
    },
    // {
    //   value: "2",
    //   title: t("Other products"),
    //   icon: <FontAwesomeIcon icon={faMapPin} />,
    // },
    {
      value: "1",
      title: t("Show results"),
      icon: <FontAwesomeIcon icon={faNewspaper} />,
    },
    {
      value: "3",
      title: t("Not showing"),
      icon: <FontAwesomeIcon icon={faEyeSlash} />,
    },
  ];

  useEffect(() => {
    if (category.length > 0) {
      setAgricultureFilterData().then((res) => {
        setTotalData(res.length);
        setFilteredData(res.slice(limited.begin, limited.end));
      });
    }
  }, [
    agricultureTab,
    agricultureData,
    page,
    rowsPerPage,
    pageControl,
    category,
    textSearch,
  ]);

  const setAgricultureFilterData = async () => {
    const filtered = await agricultureData.filter((f) => {
      /* กรอง Tab */
      if (agricultureTab === "1" && f.display === 0) {
        return false;
      } else if (agricultureTab === "2" && f.pin === 0) {
        return false;
      } else if (agricultureTab === "3" && f.display === 1) {
        return false;
      }

      /* กรอง Page */
      if (pageControl !== "") {
        let category = f.category;
        if (!category.includes(`${pageControl}`)) {
          return false;
        }
      }

      if (agricultureTab === "0") {
        return f;
      }

      return f;
    });

    const maped = await filtered.map((d) => {
      const cateLists = d.category;
      return { ...d, cateLists };
    });

    if (!textSearch || textSearch.trim() === "") {
      return maped;
    } else {
      const fitered = maped?.filter(
        (item) =>
          item && item.keyword.toLowerCase().includes(textSearch.toLowerCase())
      );
      return fitered;
    }
  };

  const handleTabChange = (event, newValue) => {
    props.setAgricultureTab(newValue);
  };

  /* Pagination */
  const handleChangePage = (event, newPage) => {
    setLimited({
      begin: newPage * rowsPerPage,
      end: (newPage + 1) * rowsPerPage,
    });
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    let rowPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowPage);
    setLimited({ begin: 0, end: rowPage });
    setPage(0);
  };

  const addHandler = (item) => {
    setItems(item);
    setProductModalEdit({
      isEdit: false,
      isOpen: true,
    });
  };

  const editHandler = (item) => {
    setItems(item);
    setProductModalEdit({
      isEdit: true,
      isOpen: true,
    });
  };
  const deleteHandler = (item) => {
    let buttonIcon = item.is_maincontent === 1 ? "question" : "warning";
    let text = item.is_maincontent === 1 ? " ( Main Content )" : " Content";

    modalSwal
      .fire({
        icon: buttonIcon,
        title: "Delete" + text,
        text: `Do you want to delete "${item.title}" ?`,
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeleteProduc(item.id, item.language).then((res) => {
            SwalUI({ status: res.status, description: res.description });
            if (res.status) {
              props.setRefreshData((prev) => prev + 1);
            }
          });
        }
      });
  };

  return (
    <Fragment>
      <Box className="product-tab-section" sx={{ width: "100%" }}>
        <TabContext value={agricultureTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              {tabLists.map((tab) => (
                <Tab
                  className="post-tab-header"
                  value={tab.value}
                  key={tab.value}
                  icon={tab.icon}
                  label={t(tab.title)}
                />
              ))}
            </TabList>
          </Box>
          {tabLists.map((tab) => (
            <TabPanel
              className={`post-tab-body ${isRowDisplay ? "asRow" : "asColumn"}`}
              value={tab.value}
              key={tab.value}
            >
              <div className="item-list">
                {filteredData.map((item, index) => (
                  <ContentCardUI
                    key={index}
                    onAddClick={() => addHandler(item)}
                    onEditClick={() => editHandler(item)}
                    onDeleteClick={() => deleteHandler(item)}
                    mainContent={item.is_maincontent}
                    allowDelete={true}
                    className="post-card-content"
                    data={{
                      alt: item.thumbnail_alt,
                      image: item.thumbnail_link,
                      language: item.language,
                    }}
                    isRowDisplay={isRowDisplay}
                  >
                    <h3 className="title">
                      {/* {isSuerperAdmin && (
                        <span className={"id"} title="ref id">
                          [ {item.id} ]
                        </span>
                      )} */}
                      {item.keyword}
                      {/* {item.is_maincontent ? (
                        <span className={"id"} title="ref id">
                          {" "}
                          ( Main Content )
                        </span>
                      ) : (
                        <></>
                      )} */}
                    </h3>
                     <p>{t('ModalCategorieskeyword')} : {item.slug}</p>
                     <p>{t('ModalCategoriesTitle')} : {item.title}</p>
                    {item.short_url&& (<a href={webPath+item.short_url} target="_blank" style={{color:'red'}}><p>{t('url')} : {webPath}{item.short_url}</p></a>)}
                    {/* <p className="cate">
                      <span className="fa-icon" title="category">
                        {" "}
                        <FontAwesomeIcon icon={faLink} />
                      </span>
                       <span>
                        {item.cateLists.map((c, index) =>
                          index > 0 ? ` , ${c.title}` : c.title
                        )}
                      </span> 
                    </p>*/}
                    <p className="editor">
                      {/* {item.editorName && (
                        <>
                          <span className="fa-icon" title="editor">
                            <FontAwesomeIcon icon={faPenNib} />
                          </span>
                          <span>{item.editorName}</span>
                        </>
                      )} */}
                      <span className="fa-icon" title="editor">
                        <FontAwesomeIcon icon={faLanguage} />
                        <b>{item.language.toUpperCase()}</b>
                      </span>

                      <span className="fa-icon" title="editor">
                        <FontAwesomeIcon icon={faArrowDownShortWide} />
                        <b>{item.priority}</b>
                      </span>

                      {item.pin ? (
                        <span className="fa-icon" title="editor">
                          <FontAwesomeIcon icon={faMapPin} />
                        </span>
                      ) : (
                        <></>
                      )}

                      {item.display ? (
                        <span className="fa-icon" title="editor">
                          <FontAwesomeIcon icon={faNewspaper} />
                        </span>
                      ) : (
                        <span className="fa-icon" title="editor">
                          <FontAwesomeIcon icon={faEyeSlash} />
                        </span>
                      )}
                    </p>
                  </ContentCardUI>
                ))}
              </div>
              <TablePagination
                component="div"
                count={totalData}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
              />
            </TabPanel>
          ))}
        </TabContext>
      </Box>

      {productModalEdit.isOpen && (
        <AgricultureEditMpdal
          t={t}
          items={items}
          category={category}
          setRefreshData={props.setRefreshData}
          totalData={totalData}
          setCategory={props.setCategory}
          isEdit={productModalEdit.isEdit}
          isOpen={productModalEdit.isOpen}
          setClose={setProductModalEdit}
        />
      )}
    </Fragment>
  );
}

export default AgricultureTab;
