import { useState, Fragment, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
  Box,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faRedo,
  faGear,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

import { appActions } from "../../../../store/app-slice";
import PreviewImageUI from "../../../../components/ui/preview-image/preview-image";
import FieldsetUI from "../../../../components/ui/fieldset/fieldset";
import ButtonUI from "../../../../components/ui/button/button";
import SwalUI from "../../../../components/ui/swal-ui/swal-ui";
import { svUpdateProducCate } from "../../../../services/productServices/productcate.service";

function CategoryEditModal(props) {
  const { t, setRefreshData, open, setOpen, isEdit, productEdit } = props;
  const thumbnailDefault = {
    thumbnail: true,
    src: "",
    file: null,
    name: null,
    remove: false,
  };

  const [isError, setIsError] = useState({
    thumbnail: false,
    title: false,
  });

  const uploadPath = useSelector((state) => state.app.uploadPath);
  const language = useSelector((state) => state.app.language);

  const [previews, setPreviews] = useState(thumbnailDefault);
  const [editData, setEditData] = useState(productEdit);
  const [isFetching, setIsFetching] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    /* Set thumbnail */
    let thumbnail = uploadPath + productEdit.thumbnail_link;
    setPreviews({
      file: null,
      src: thumbnail,
      remove: true,
      srcDefault: thumbnail,
    });
  }, []);

  const setPreviewHandler = (data) => {
    setEditData({ ...editData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority + 1 };
      });
    } else if (editData.priority > 1) {
      setEditData((prevState) => {
        return { ...prevState, priority: editData.priority - 1 };
      });
    }
  };

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      title: editData.title.trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("Image", previews.file);
        formData.append("ImageName", editData.imageName);
      }
      formData.append("thumbnail_link", editData.thumbnail_link);
      formData.append("thumbnail_title", editData.thumbnail_title);
      formData.append("thumbnail_alt", editData.thumbnail_alt);
      formData.append("id", editData.id);
      formData.append("title", editData.title);
      formData.append("details", editData.details);
      formData.append("description", editData.description);
      formData.append("display", editData.display ? 1 : 0);
      formData.append("pin", editData.pin ? 1 : 0);
      formData.append("priority", editData.priority);
      formData.append("language", isEdit ? editData.language : language);
      /* Display the key/value pairs*/
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      // return false;
      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);

    svUpdateProducCate(editData.id, formData).then((res) => {
      setOpen(false);
      setIsFetching(false);
      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };
  // console.log(editData);
  return (
    <Fragment>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="category-add-modal">
          <section id="category-add-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <div className="head-title">
                    <FontAwesomeIcon icon={faAdd} /> {t("AddCate")}
                  </div>
                </div>
              </div>
              <div className="card-body" ref={scrollRef}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  {/* ข้อมูลรูปภาพ */}
                  <FieldsetUI
                    className={`image-setting ${
                      isError.thumbnail ? "error" : ""
                    }`}
                    label={t("Image Info")}
                  >
                    <PreviewImageUI
                      className="add-image"
                      previews={previews}
                      setPreviews={setPreviewHandler}
                      setData={setEditData}
                    />
                    <div className="image-detail">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return { ...prevState, imageName: e.target.value };
                          })
                        }
                        value={editData.imageName}
                        className={`text-field-custom ${
                          !previews.file ? "inp-hidden" : ""
                        }`}
                        fullWidth={true}
                        error={false}
                        id="image-name"
                        label="Image name"
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_title: e.target.value,
                            };
                          })
                        }
                        value={editData.thumbnail_title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-title"
                        label="Image title"
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_alt: e.target.value,
                            };
                          })
                        }
                        value={editData.thumbnail_alt}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-tag"
                        label="Alt description"
                        size="small"
                      />
                    </div>
                  </FieldsetUI>
                  {/* รายละเอียด */}
                  <div className="category-details">
                    <h3 className="category-detail title">
                      {t("ModalDetail")}
                    </h3>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return { ...prevState, title: e.target.value };
                          })
                        }
                        value={editData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่อหมวดหมู่"
                        size="small"
                      />
                    </div>

                    {/*
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              details: e.target.value,
                            };
                          })
                        }
                        value={editData.details}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-details"
                        label="รายละเอียดหมวดหมู่"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              description: e.target.value,
                            };
                          })
                        }
                        value={editData.description}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-description"
                        label="คำอธิบายหมวดหมู่"
                        size="small"
                        multiline
                      />
                    </div>
                  */}

                    {/* Settings */}
                    <div className="group-settings">
                      <h3 className="category-detail-title">{t("Settings")}</h3>
                      <div className="setting-controls">
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setEditData({
                                      ...editData,
                                      display: e.target.checked,
                                    })
                                  }
                                  checked={editData.display ? true : false}
                                />
                              }
                              label={t("การแสดงผล")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        {/* <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setEditData({
                                      ...editData,
                                      pin: e.target.checked,
                                    })
                                  }
                                  checked={editData.pin ? true : false}
                                />
                              }
                              label={t("Pin")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div> */}
                        <div className="input-group-settings">
                          <div className="inp">
                            <ButtonUI
                              color="error"
                              onClick={(e) => priorityHandler(false)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </ButtonUI>
                            <span className="title">
                              {t("Priority")} {editData.priority}
                            </span>
                            <ButtonUI onClick={(e) => priorityHandler(true)}>
                              <FontAwesomeIcon icon={faAdd} />
                            </ButtonUI>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              {/* ปุ่ม */}
              <div className="btn-action">
                <ButtonUI
                  // loader={true}
                  isLoading={isFetching}
                  onClick={editValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default CategoryEditModal;
