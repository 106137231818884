import { useState, Fragment, useRef, useEffect } from "react";
import {
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
  Switch,
  Box,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faEdit,
  faRedo,
  faGear,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import ButtonUI from "../../../components/ui/button/button";
import { svUpdateMember } from "../../../services/member.Service";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";

function MessagesContactRead(props) {
  const { t, read, open, setOpen } = props;

  return (
    <Fragment>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title" //messages-contact-modal-title
        aria-describedby="parent-modal-description" //messages-contact-modal-description
      >
        <Box className="messages-contact-read-modal">
          <section id="messages-contact-read-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faCircleInfo} /> {t("ColumnsTopoic")}:{" "}{read.topic}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="messages-contact-details">
                    <div className="input-half">
                      <Typography variant="subtitle1" gutterBottom>
                        {t("ColumnsFullname")} :{" "}
                        {read.fullname}
                      </Typography>
                    </div>
                    <div className="input-half">
                      <Typography variant="subtitle1" gutterBottom>
                        {t("ColumnsEmail")} :{" "}
                        {read.email}
                      </Typography>
                    </div>
                    <div className="input-full">
                      <Typography variant="subtitle1" gutterBottom>
                        {t("ColumnsMessage")} :{" "}
                        {read.message}
                      </Typography>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default MessagesContactRead;
