import axios from "axios";

/* cate */
export const svProducCate = (language) => {
    return axios
        .get(`productcate/data?language=${language}`)
        .then((res) => res)
        .catch((err) => err);
};

export const svCreateProducCate = (formData) => {
    return axios
        .post(`productcate/create`, formData)
        .then((res) => res)
        .catch((err) => err);
};

export const svUpdateProducCate = (id, formData) => {
    return axios
        .post(`productcate/update/${id}`, formData)
        .then((res) => res)
        .catch((err) => err);
};

export const svDeleteProducCate = (id,language) =>{
    return axios
    .delete(`productcate/destroy/${id}/${language}`)
    .then((res) => res)
    .catch((err) => err);
}