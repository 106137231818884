import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import { svDeleteMember } from "../../../services/member.Service";
import moment from "moment";
import { Switch, Paper } from "@mui/material";
import ButtonUI from "../../../components/ui/button/button";
import DateMoment from "../../../components/ui/date-moment/date-moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
const modalSwal = withReactContent(Swal);

function MemberTables(props) {
  const {
    t,
    setRefreshData,
    setOpenEdit,
    setMemberEdit,
    memberData,
    textSearch,
    pageControl,
  } = props;

  const [totalData, setTotalData] = useState(-1);
  const uPermission = useSelector((state) => state.auth.userPermission);
  const [filteredData, setFilteredData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [limited, setLimited] = useState({ begin: 0, end: rowsPerPage });

  const columns = [
    {
      //   width: 150,
      flex: 1,
      field: "display_name",
      headerName: t("DisplayName"),
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      headerClassName: "table-columns",
    },
    {
      //   width: 150,
      flex: 1,
      field: "firstname",
      headerName: t("firstname-lastname"),
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <div style={{ whiteSpace: "break-spaces" }}>
          <p>{params.row.firstname + " " + params.row.lastname}</p>
        </div>
      ),
    },
    {
      //   width: 150,
      flex: 1,
      field: "phone_number",
      headerName: t("PhoneNumber"),
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 150,
      flex: 0.5,
      field: "member_note",
      headerName: t("MemberNote"),
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      // renderCell: (params) => (
      //   <div style={{ whiteSpace: "break-spaces" }}>
      //     <p>{params.row.details}</p>
      //   </div>
      // ),
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "member_verify_at",
      headerName: t("MemberVerify"),
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {<DateMoment format={"LL"} date={params.row.member_verify_at} />}
          {/* {params.row.member_verify_at && (new Date(params.row.member_verify_at).toLocaleString("en-GB", { timeZone: "UTC" ,year: 'numeric', month: '2-digit',day: '2-digit'}))} */}
        </>
      ),
    },
    {
      //   width: 140,
      flex: 1,
      field: "remaining_date",
      headerName: t("remainingDate"),
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          <div>
            {moment(params.row.member_expire_at).diff(moment(), "days") < 0
              ? t("หมดอายุ")
              : moment(params.row.member_expire_at)
                  .add(1, "d")
                  .diff(moment(), "days") ||
                (moment(params.row.member_expire_at).diff(moment(), "days") ==
                  isNaN(
                    moment(params.row.member_expire_at).diff(moment(), "days")
                  ) &&
                  "  ")}
          </div>
          {/* params.row.member_expire_at.diff(moment()) */}
          {/* {params.row.member_expire_at && (new Date(params.row.member_expire_at).toLocaleString("en-GB", { timeZone: "UTC" ,year: 'numeric', month: '2-digit',day: '2-digit'}))} */}
        </>
      ),
    },
    {
      //   width: 140,
      flex: 1,
      field: "member_expire_at",
      headerName: t("MemberExpire"),
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {<DateMoment format={"LL"} date={params.row.member_expire_at} />}
          {/* {params.row.member_expire_at && (new Date(params.row.member_expire_at).toLocaleString("en-GB", { timeZone: "UTC" ,year: 'numeric', month: '2-digit',day: '2-digit'}))} */}
        </>
      ),
    },
    {
      //   width: 90,
      flex: 1,
      field: "action",
      headerName: t("DataGridHandle"),
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {(uPermission.superAdmin || uPermission.admin) && (
            <div className="box-action">
              <ButtonUI
                onClick={() => editHandle(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
              >
                {t("")}
              </ButtonUI>
              <ButtonUI
                onClick={() => deleteHandle(params.row)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
              >
                {t("")}
              </ButtonUI>
            </div>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setMemberFilterData().then((res) => {
      setTotalData(res.length);
      setFilteredData(res.slice(limited.begin, limited.end));
    });
  }, [memberData, pageControl, textSearch.trim() === ""]);

  const setMemberFilterData = async () => {
    const filtered = await memberData.filter((f) => {
      /* กรอง Page */
      if (pageControl !== "" || pageControl != 0) {
        let category = f.member_status;
        let showmember;
        if (pageControl == 1) {
          showmember = category == 1;
          return showmember;
        } else if (pageControl == 2) {
          showmember = category == 0;
          return showmember;
        } else if (pageControl == 3) {
          showmember = moment(f.member_expire_at).diff(moment(), "days") < 3;
          return showmember;
        }
      }
      return f;
    });

    const maped = await filtered;
    // .map((d) => {
    //   const status = d.member_status;
    //   return { ...d, status };
    // });

    if (!textSearch || textSearch.trim() === "") {
      return maped;
    } else {
      const fitered = maped?.filter(
        (item) =>
          item &&
          (item.display_name.toString().toLowerCase().includes(textSearch) ||
            item.firstname.toLowerCase().includes(textSearch) ||
            item.phone_number.toString().toLowerCase().includes(textSearch))
      );
      return fitered;
    }
  };

  const editHandle = (row) => {
    // console.log(row);
    setMemberEdit(row);
    setOpenEdit(true);
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeleteMember(row.account_id).then((res) => {
            if (res.status === 200) {
              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
              setRefreshData((prev) => prev + 1);
            } else {
              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
            }
          });
        }
      });
  };
  // const rowsClassName = "table-rows";

  const getRowClassName = (params) => {
    const diffDays = moment(params.row.member_expire_at)
      .add(1, "d")
      .diff(moment(), "days");
    // const ckeckMember = filteredData.find(
    //   (product) => product.product_phone === params.row.product_phone
    // );

    // กำหนด className ตามผลลัพธ์ของการเปรียบเทียบ
    if (diffDays > 0 && diffDays <= 3) {
      return "even1-row"; // คืนค่า 'duplicate-row' สำหรับแถวที่มีเบอร์โทรศัพท์ซ้ำ
    } else if (diffDays <= 0) {
      return "even2-row";
    } else {
      return "table-rows"; // คืนค่า 'unique-row' สำหรับแถวที่มีเบอร์โทรศัพท์ไม่ซ้ำ
    }
  };

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            getRowClassName={getRowClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            rowHeight={100}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}

export default MemberTables;
