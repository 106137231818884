import {
  faEnvelope,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { t } from "i18next";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataGridContactMs from "./datagrid/DataGridContactMs";
import ContactMsModal from "./ContactMsModal";

const ContactMsTab = ({
  filteredData,
  filterData,
  tabSelect,
  setRefreshData,
  setTabSelect,
  setFilteredData,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [mailShow, setMailShow] = useState(null);

  const tabLists = [
    {
      value: "0",
      title: "ทั้งหมด",
      icon: <FontAwesomeIcon icon={faEnvelope} />,
    },
    {
      value: "3",
      title: "ติดดาว",
      icon: <FontAwesomeIcon icon={faStar} style={{ style: "regular" }} />,
    },
  ];

  const handleChange = (event, newValue) => {
    setTabSelect(newValue);
  };

  return (
    <Fragment>
      <Box className="orders-tab-section" sx={{ width: "100%" }}>
        <TabContext value={tabSelect}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              className={`tab-header`}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              {tabLists.map((tab) => (
                <Tab
                  className="orders-tab-head-field"
                  value={tab.value}
                  key={tab.value}
                  icon={tab.icon}
                  iconPosition="start"
                  label={t(tab.title)}
                />
              ))}
            </TabList>
          </Box>
          <DataGridContactMs
            setFilteredData={setFilteredData}
            filterData={filterData}
            filteredData={filteredData}
            setOpenModal={setOpenModal}
            setMailShow={setMailShow}
            setRefreshData={setRefreshData}
          />
        </TabContext>
      </Box>
      {openModal && (
        <ContactMsModal
          open={openModal}
          setOpen={setOpenModal}
          mailShow={mailShow}
        />
      )}
    </Fragment>
  );
};

export default ContactMsTab;
