import { useState, Fragment, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
  Box,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faRedo,
  faGear,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import ButtonUI from "../../../components/ui/button/button";
import { svUpdateMember } from "../../../services/member.Service";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { appActions } from "../../../store/app-slice";

function MemberModalEdit(props) {
  const { t, memberEdit, open, setOpen, isEdit, setRefreshData } = props;
  const [isError, setIsError] = useState({
    displayDates: false,
  });
  const [editData, setEditData] = useState(memberEdit);
  const [isFetching, setIsFetching] = useState(false);
  const [displayDate, setDisplayDate] = useState({
    display_date: editData.member_expire_at
      ? moment(editData.member_expire_at).diff(moment(), "days")
      : null,
  });
  // const [hiddenDate, setHiddenDate] = useState(memberEdit.member_expire_at);
  const scrollRef = useRef(null);
  const dispatch = useDispatch();

  const displayHandleChange = (newValue) => {
    console.log(newValue.target.value);
    // setDisplayDate(newValue);
  };

  // const hiddenHandleChange = (newValue) => {
  //   setHiddenDate(newValue);
  // };

  const editValidators = () => {
    let isValid = true;
    let error = {
      ...isError,
      displayDates: !displayDate.display_date || displayDate.display_date == 0,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      formData.append("member_id", editData.id);
      formData.append("member_status", editData.member_status ? 1 : 0);
      formData.append(
        "member_note",
        editData.member_status ? "active" : "inactive"
      );
      formData.append(
        "member_expire",
        displayDate.display_date
      ); /*moment(hiddenDate).format()=== "Invalid date" ? "Not specified": moment(hiddenDate).format()) */

      /* Display the key/value pairs*/
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);

    svUpdateMember(formData).then((res) => {
      setOpen(false);
      setIsFetching(false);
      console.log(res);
      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        const pending = res.data.check.memberWaitactive;
        const active = res.data.check.memberExpire;
        dispatch(appActions.getMemberStatus({ pending, active }));
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };
  // console.log(editData);
  return (
    <Fragment>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="member-edit-modal">
          <section id="member-edit-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faAdd} /> {t("AddCate")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="member-details">
                    <h3 className="member-detail-title">{t("detailUser")}</h3>

                    <div className="input-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              firstname: e.target.value,
                            };
                          })
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        value={editData.firstname}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="et-firstname"
                        label={t("FirstName")}
                        size="small"
                      />
                    </div>
                    <div className="input-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              lastname: e.target.value,
                            };
                          })
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        value={editData.lastname}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="et-lastname"
                        label={t("LastName")}
                        size="small"
                      />
                    </div>

                    <div className="input-half">
                      <TextField
                        id="ed-display-name"
                        InputProps={{
                          readOnly: true,
                        }}
                        label={t("displayName")}
                        value={editData.display_name}
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              display_name: e.target.value,
                            };
                          })
                        }
                        fullWidth={true}
                        //   error={isError.display_name}
                        className="text-field-custom"
                        size="small"
                      />
                    </div>
                    <div className="input-half">
                      <TextField
                        onChange={(e) =>
                          setEditData((prevState) => {
                            return {
                              ...prevState,
                              phone_number: e.target.value,
                            };
                          })
                        }
                        value={editData.phone_number}
                        InputProps={{
                          readOnly: true,
                        }}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="et-phone_number"
                        label={t("PhoneNumber")}
                        size="small"
                      />
                    </div>

                    {/* Settings */}
                    <div className="group-settings">
                      <h3 className="category-detail-title">{t("Settings")}</h3>
                      <div className="setting-controls">
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setEditData({
                                      ...editData,
                                      member_status: e.target.checked,
                                    })
                                  }
                                  checked={
                                    editData.member_status ? true : false
                                  }
                                />
                              }
                              sx={{ marginTop: 1 }}
                              label={t("active")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        <div className="inp-group">
                          {/* <TextField
                            onChange={(e) =>
                              setDisplayDate((prevState) => {
                                return {
                                  ...prevState,
                                  display_date: e.target.value,
                                };
                              })
                            }
                            style={{ width: "100%" }}
                            value={ displayDate.display_date}
                            className="text-field-custom"
                            fullWidth={true}
                            error={isError.displayDates}
                            id="et-phone_number"
                            label={t("MemberExpire")}
                            size="small"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              min: 0,
                            }}
                          /> */}
                          <TextField
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              // ใช้ Regex เพื่อตรวจสอบว่า input ตรงตามเงื่อนไขหรือไม่
                              if (/^[0-9]*$/.test(inputValue)) {
                                // ถ้า input ถูกต้องก็อนุญาตให้ setState
                                setDisplayDate((prevState) => ({
                                  ...prevState,
                                  display_date: inputValue,
                                }));
                              }
                            }}
                            style={{ width: "100%" }}
                            value={displayDate.display_date}
                            className="text-field-custom"
                            fullWidth={true}
                            error={isError.displayDates}
                            id="et-phone_number"
                            label={t("MemberExpire")}
                            size="small"
                            type="text" // เปลี่ยน type เป็น text แทน number เพื่อให้รับทุกประเภทของอักขระ
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              maxLength: 10, // จำกัดความยาวของ input เป็น 10 ตัวอักษร
                            }}
                          />
                        </div>
                        <div className="input-date"></div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              {/* ปุ่ม */}
              <div className="btn-action">
                <ButtonUI
                  // loader={true}
                  isLoading={isFetching}
                  onClick={editValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default MemberModalEdit;
