import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

import "./agriculture-modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faMinus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  IconButton,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  TextField,
  FormGroup,
  Switch,
} from "@mui/material";

import ButtonUI from "../../../../components/ui/button/button";
import FieldsetUI from "../../../../components/ui/fieldset/fieldset";
import PreviewImageUI from "../../../../components/ui/preview-image/preview-image";
import { svCreateProduct } from "../../../../services/productServices/pageagriculture.service";
import SwalUI from "../../../../components/ui/swal-ui/swal-ui";
import CKeditorComponent from "../../../../components/ui/ckeditor/ckeditor";

function AgricultureAddModal(props) {
  const { t, isOpen, category, maxPriority, setClose, setRefreshData } = props;
  const [previews, setPreviews] = useState({ src: "", file: null, name: null });
  const [isError, setIsError] = useState({
    thumbnail: false,
    slug: false,
    // keyword:false,
    title: false,
    category: false,
    description: false,
    seement: false,
    hr: false,
    ir: false,
  });

  const initProductData = {
    // keyword:"",
    slug:"",
    title: "",
    description: "",
    seement: "",
    hr: "",
    ir: "",
    category: "",
    imageName: "",
    thumbnail_title: "",
    thumbnail_alt: "",
    display: true,
    pin: false,
    priority: maxPriority + 1,
  };

  // const language = useSelector((state) => state.app.language);
  const defaultLanguage = useSelector((state) => state.app.defaultLanguage);
  const [addData, setAddData] = useState(initProductData);
  const [ckValue, setCkValue] = useState("")
  const [isFetching, setIsFetching] = useState(false);
  const scrollRef = useRef(null);

  const setPreviewHandler = (data) => {
    setAddData({ ...addData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setAddData((prevState) => {
        return { ...prevState, priority: parseInt(addData.priority) + 1 };
      });
    } else if (addData.priority > 1) {
      setAddData((prevState) => {
        return { ...prevState, priority: parseInt(addData.priority) - 1 };
      });
    }
  };

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      category: addData.category === "",
      thumbnail: previews.file === null || !previews.file,
      title: addData.title.trim().length < 1,
      // description: addData.description.trim().length < 1,
      slug: addData.slug.trim().length < 1,

    };
    setIsError(() => error);

    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("Image", previews.file);
        formData.append("ImageName", addData.imageName);
      }
      formData.append("thumbnail_title", addData.thumbnail_title);
      formData.append("thumbnail_alt", addData.thumbnail_alt);

      formData.append("category", addData.category);
      formData.append("title", addData.title);
      formData.append("description", ckValue);
      formData.append("slug", addData.slug);
      // formData.append("keyword", addData.keyword);
      formData.append("seement", addData.seement ? addData.seement : "N/A");
      formData.append("hr", addData.hr ? addData.hr : "N/A");
      formData.append("ir", addData.ir ? addData.ir : "N/A");
      // formData.append("link_facebook", addData.link_facebook);
      // formData.append("link_twitter", addData.link_twitter);

      formData.append("display", addData.display ? 1 : 0);
      formData.append("pin", addData.pin ? 1 : 0);
      formData.append("priority", addData.priority);
      formData.append("language", defaultLanguage);
      /* Display the key/value pairs*/
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      // return false;
      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);

    svCreateProduct(formData).then((res) => {
      console.log(res);
      
      if (res.status === 200) {
        setClose(false);
      setIsFetching(false);
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        setClose(true);
        setIsFetching(false);
        console.log('123');
        let error = {
          ...isError,
          slug: true,
    
        };
        setIsError(() => error);
        // SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };
  // console.log(addData.priority);
  return (
    <Modal
      disableEnforceFocus
      open={isOpen}
      onClose={() => setClose(false)}
      className={"modal-add-agriculture"}
      aria-labelledby="modal-add-agriculture"
      aria-describedby="modal-add-agriculture"
    >
      <Box className="modal-custom">
        <div className="modal-header">
          <h2>
            <FontAwesomeIcon icon={faAdd} />
            <span>{t("AgriAdd")}</span>
          </h2>
          <IconButton
            className="param-generator"
            color="error"
            sx={{ p: "10px" }}
            onClick={() => setClose(false)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </div>

        <div className="modal-body overflow-scroll-custom">
          <fieldset className="modal-fieldset">
            {/* RadioBoxUI */}
            <div
              className={`box-checkbox-container cate-menu-list 
              ${isError.category ? "error" : ""}`}
            >
              <div className="brc-body overflow-scroll-custom">
                <FormControl className="brc-form-control">
                  <FormLabel id="box-checkbox">{t("Category")}</FormLabel>
                  <RadioGroup
                    aria-labelledby="box-checkbox"
                    name="controlled-checkbox-buttons-group"
                  >
                    {category &&
                      category.map((e) => {
                        return (
                          <FormControlLabel
                            key={e.id}
                            onChange={(e) =>
                              setAddData((prevState) => {
                                return {
                                  ...prevState,
                                  category: e.target.value,
                                };
                              })
                            }
                            value={e.id}
                            control={<Radio />}
                            label={e.title}
                          />
                        );
                      })}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="form-details">
              {/* รูป */}
              <FieldsetUI
                className={`image-setting ${isError.thumbnail ? "error" : ""}`}
                label={t("Image Info")}
              >
                <PreviewImageUI
                  className="add-image"
                  previews={previews}
                  setPreviews={setPreviewHandler}
                  setData={setAddData}
                />
                <div className="image-detail">
                  <TextField
                    onChange={(e) =>
                      setAddData((prevState) => {
                        return { ...prevState, imageName: e.target.value };
                      })
                    }
                    value={addData.imageName}
                    className={`text-field-custom ${
                      !previews.file ? "inp-hidden" : ""
                    }`}
                    fullWidth={true}
                    error={false}
                    id="image-name"
                    label={t("ModalImageName")}
                    size="small"
                  />
                  <TextField
                    onChange={(e) =>
                      setAddData((prevState) => {
                        return {
                          ...prevState,
                          thumbnail_title: e.target.value,
                        };
                      })
                    }
                    value={addData.thumbnail_title}
                    className="text-field-custom"
                    fullWidth={true}
                    error={false}
                    id="image-title"
                    label={t("ModalImageTitle")}
                    size="small"
                  />
                  <TextField
                    onChange={(e) =>
                      setAddData((prevState) => {
                        return {
                          ...prevState,
                          thumbnail_alt: e.target.value,
                        };
                      })
                    }
                    value={addData.thumbnail_alt}
                    className="text-field-custom"
                    fullWidth={true}
                    error={false}
                    id="image-tag"
                    label={t("ModalAltDescription")}
                    size="small"
                  />
                </div>
              </FieldsetUI>
              {/* รายละเอียด */}
              <div className="category-details">
                <h3 className="category-detail-title">{t("ModalDetail")}</h3>
                <div className="input-full">
                  <TextField
                    onChange={(e) =>
                      setAddData((prevState) => {
                        return { ...prevState, slug: e.target.value };
                      })
                    }
                    value={addData.slug}
                    className="text-field-custom"
                    fullWidth={true}
                    error={isError.slug}
                    id="ad-slug"
                    label={t("ModalCategorieskeyword")}
                    size="small"
                  />
                </div>
                <div className="input-full">
                  <TextField
                    onChange={(e) =>
                      setAddData((prevState) => {
                        return { ...prevState, title: e.target.value };
                      })
                    }
                    value={addData.title}
                    className="text-field-custom"
                    fullWidth={true}
                    error={isError.title}
                    id="ad-title"
                    label={t("ModalCategoriesTitle")}
                    size="small"
                  />
                </div>
                <div className="ck-content">
                  <label className="ck-add-agriculture">{t('ModalAgricultureDescription')}</label>
                  <CKeditorComponent
                    className="ck-add-agriculture"
                    ckNameId="ck-add-agriculture"
                    value={ckValue}
                    onUpdate={setCkValue}
                  />
                </div>
                <div className="input-half">
                  <TextField
                    onChange={(e) =>
                      setAddData((prevState) => {
                        return { ...prevState, seement: e.target.value };
                      })
                    }
                    value={addData.seement}
                    className="text-field-custom"
                    fullWidth={true}
                    error={isError.seement}
                    id="ad-seement"
                    label={t("segment")}
                    size="small"
                  />
                </div>
                <div className="input-half">
                  <TextField
                    onChange={(e) =>
                      setAddData((prevState) => {
                        return { ...prevState, hr: e.target.value };
                      })
                    }
                    value={addData.hr}
                    className="text-field-custom"
                    fullWidth={true}
                    error={isError.hr}
                    id="ad-hr"
                    label={t("hr")}
                    size="small"
                  />
                </div>
                <div className="input-half">
                  <TextField
                    onChange={(e) =>
                      setAddData((prevState) => {
                        return { ...prevState, ir: e.target.value };
                      })
                    }
                    value={addData.ir}
                    className="text-field-custom"
                    fullWidth={true}
                    error={isError.ir}
                    id="ad-ir"
                    label={t("ir")}
                    size="small"
                  />
                </div>
               {/*   <div className="input-full">
                  <TextField
                    onChange={(e) =>
                      setAddData((prevState) => {
                        return { ...prevState, link_facebook: e.target.value };
                      })
                    }
                    value={addData.link_facebook}
                    className="text-field-custom"
                    fullWidth={true}
                    error={false}
                    id="ad-linkfacebook"
                    label={t("Link Facebook")}
                    size="small"
                  />
                </div>
                <div className="input-full">
                  <TextField
                    onChange={(e) =>
                      setAddData((prevState) => {
                        return { ...prevState, link_twitter: e.target.value };
                      })
                    }
                    value={addData.link_twitter}
                    className="text-field-custom"
                    fullWidth={true}
                    error={false}
                    id="ad-linktwitter"
                    label={t("Link Twitter")}
                    size="small"
                  />
                </div>*/}
                {/* Settings */}
                <div className="group-settings">
                  <h3 className="post-detail-titile">{t("Settings")}</h3>
                  <div className="setting-controls">
                    <div className="switch-form">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  display: e.target.checked,
                                })
                              }
                              checked={addData.display ? true : false}
                            />
                          }
                          label={t("display")} //การแสดงผล
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </div>
                    {/* <div className="switch-form">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  pin: e.target.checked,
                                })
                              }
                              checked={addData.pin ? true : false}
                            />
                          }
                          label={t("Pin")}
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </div> */}
                    <div className="input-group-settings">
                      <div className="inp">
                        <ButtonUI
                          color="error"
                          onClick={(e) => priorityHandler(false)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </ButtonUI>
                        <span className="title">
                          {t("Priority")} {addData.priority}
                        </span>
                        <ButtonUI onClick={(e) => priorityHandler(true)}>
                          <FontAwesomeIcon icon={faAdd} />
                        </ButtonUI>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div className="modal-footer">
          {/* ปุ่ม */}
          <ButtonUI
            loader={true}
            isLoading={isFetching}
            className="btn-save"
            on="save"
            width="md"
            onClick={addValidators}
          >
            {t("Save")}
          </ButtonUI>
          <ButtonUI
            className="btn-cancel"
            on="cancel"
            width="md"
            onClick={() => setClose(false)}
          >
            {t("Cancel")}
          </ButtonUI>
        </div>
      </Box>
    </Modal>
  );
}

export default AgricultureAddModal;
