import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import appConfig from './store/settings'; 

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
      backend: {
        loadPath: appConfig.defaultURL + 'locales/{{lng}}/{{ns}}.json' // กำหนด path ใหม่สำหรับโหลดไฟล์ locales
      },
      fallbackLng: appConfig.languageAvailable,
      debug: false,
      sSeparator: ':',
      keySeparator: ':',
      ns: ["translations"],
      defaultNS: "translations",
      interpolation: {
        escapeValue: false
      },
      lng: appConfig.defaultLanguage, // เพิ่มค่านี้เพื่อตั้งค่าภาษาเริ่มต้น
});

export default i18n;

