import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { Outlet, Navigate } from 'react-router-dom';
import { authActions } from '../store/auth-slice';
import MainLayout from '../components/main';
import axios from 'axios';
import { appActions } from '../store/app-slice';
import { getMember } from '../services/member.Service';
import { getWebinfo } from '../services/webinfo.service';

const PrivateRoutes = ({ defaultURL }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const authToken = useSelector((state) => state.auth.authToken)
  const adminId = useSelector((state) => state.auth.adminId)
  const language = useSelector((state) => state.app.language)
  const multilingual = useSelector((state) => state.app.features.multilingual)
  const webinfoDetails = useSelector((state) => state.app.webinfo);
  const uploadPath = useSelector((state) => state.app.uploadPath);

  useEffect(() => {
    const favicon = document.querySelector("link[rel='icon']");
    getMember().then(res => {
      const pending = res.data.check.memberWaitactive
      const active = res.data.check.memberExpire
      dispatch(appActions.getMemberStatus({ pending, active }))
    });

    getWebinfo(language).then(res => {
      // const result= res.data
      if (favicon) {
        favicon.href = uploadPath + res.data.details.favicon.link;
      }
      const logo = res.data.details.image_1.link
      dispatch(appActions.getWebInfoDetails({ logo }))
    });
    // console.log(defaultURL);

  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      tokenHandler();
    }
  }, [isLoggedIn])

  const userInfoHandler = () => {
    dispatch(appActions.isBounceActive(true))
    return axios({
      url: `account/settings`,
      method: "post",
    }).then(response => {
      let data = response.data.data;
      dispatch(authActions.authSettings({ data: data, multilingual: multilingual }))
      dispatch(appActions.frontOffifcePageInit(data.menu))
      if (language === "en-US") {
        dispatch(appActions.changeLanguage(data.defaultLanguage))
      }
    }, (error) => {
      dispatch(authActions.logout())
    }).then(() => {
      dispatch(appActions.isBounceActive(false))
    })
  }

  const tokenHandler = () => {

    /* เช็คทุก 3 วินาที token expired จะสั่ง logout */
    try {

      if (authToken !== undefined) {
        let tokenInfo = jwt_decode(authToken);
        let tokenExpiration = tokenInfo.exp * 1000;
        let checkTokenExpire = setInterval(() => {
          if (localStorage.getItem("authToken") && tokenExpiration < new Date().getTime()) {
            clearInterval(checkTokenExpire);
            dispatch(authActions.logout())
          }
        }, 1000)

        if (!adminId) {
          userInfoHandler()
        } else {
          setTimeout(() => {
            dispatch(appActions.isBounceActive(false))
          }, 1500)
        }

      } else {
        console.log('invalid credentials')
        dispatch(authActions.logout())
      }

    } catch (error) {
      console.log('invalid authorization')
      dispatch(authActions.logout())
    }
  }

  if (isLoggedIn && !adminId) {
    return <></>
  }

  return (
    <MainLayout>
      {isLoggedIn ? <Outlet /> : <Navigate to={defaultURL + "login"} />}
    </MainLayout>
  )
}

export default PrivateRoutes