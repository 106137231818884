import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faRedo } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormControlLabel, FormGroup, Modal, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { appActions } from "../../store/app-slice";
import ButtonUI from "../../components/ui/button/button";
import SwalUI from "../../components/ui/swal-ui/swal-ui";

import { svCreateResearch } from "../../services/research.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

function ResearchAddModal({ open, setOpen, setRefreshData }) {
  const initData = {
    title: "",
    details: "",
    description: "",
    researchers: "",
    year: "",
    link: "",
    pin: false,
    display: true,
  };
  const [isError, setIsError] = useState({
    title: false,
    researchers: false,
    year: false,
    link: false,
  });

  const { t } = useTranslation("research-page");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const defaultLanguage = useSelector((state) => state.app.defaultLanguage);
  const [addData, setAddData] = useState(initData);
  const [isFetching, setIsFetching] = useState(false);

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      title: addData.title.toString().trim().length < 1,
      researchers: addData.researchers.toString().trim().length < 1,
      year: addData.year.toString().trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      formData.append("title", addData.title);
      formData.append("details", addData.details || "");
      formData.append("description", addData.description || "");
      formData.append("year", addData.year);
      formData.append("researchers", addData.researchers || "");
      formData.append("link", addData.link || "");
      formData.append("display", addData.display ? 1 : 0);
      formData.append("pin", addData.pin ? 1 : 0);
      formData.append("language", defaultLanguage);

      saveHandler(formData);
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    svCreateResearch(formData).then((res) => {
      console.log(res)

      setOpen(false);
      setIsFetching(false);
      if (res.status === 201) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="research-add-modal">
          <section id="research-add-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <h2 className="head-title">
                    <FontAwesomeIcon icon={faEdit} /> {t("แก้ไขงานวิจัย")}
                  </h2>
                </div>
              </div>
              <div className="card-body">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  <div className="research-details">
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              title: e.target.value,
                            };
                          })
                        }
                        inputProps={{ readOnly: false }}
                        value={addData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label="ชื่องานวิจัย"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              details: e.target.value,
                            };
                          })
                        }
                        value={addData.details || ""}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-details"
                        label="รายละเอียด"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              description: e.target.value,
                            };
                          })
                        }
                        value={addData.description || ""}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-description"
                        label="คำอธิบาย"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              researchers: e.target.value,
                            };
                          })
                        }
                        value={addData.researchers}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.researchers}
                        id="ad-researchers"
                        label="ผู้ทำวิจัย"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              link: e.target.value,
                            };
                          })
                        }
                        value={addData.link}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.link}
                        id="ad-link"
                        label="ลิงก์ : https://www.example.com/"
                        size="small"
                        multiline
                      />
                    </div>
                    <div className="input-md-half">
                      <DatePicker
                        className="year-input"
                        value={moment(addData.year).format("YYYY")}
                        openTo="year"
                        views={["year"]}
                        inputFormat="YYYY"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ width: "140px" }}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                              style: { textAlign: "left" },
                              placeholder: t("ปีที่ทำวิจัย"),
                              label: t("ปีที่ทำวิจัย")
                            }}
                          />
                        )}
                        onAccept={(value) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              year: moment(value).format("YYYY"),
                            };
                          })
                        }
                        onChange={() => true}
                      />
                    </div>
                    <div className="setting-controls">
                      <div className="switch-form">
                      <FormGroup>
                        <FormControlLabel  control={<Switch onChange={(e) => setAddData({...addData, display: e.target.checked})} checked={addData.display?true:false} />} label={t("การแสดงผล")} labelPlacement="start" />
                      </FormGroup>
                      </div>
                      <div className="switch-form">
                        <FormGroup>
                          <FormControlLabel  control={<Switch onChange={(e) => setAddData({...addData, pin: e.target.checked})} checked={addData.pin?true:false} /> }  label={t("Pin")}  labelPlacement="start" />
                        </FormGroup>
                      </div>
                    </div>
      
                  </div>
                </Box>
              </div>
              <div className="btn-action">
                <ButtonUI
                  isLoading={isFetching}
                  onClick={addValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("บันทึก")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("ยกเลิก")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </LocalizationProvider>
  );
}

export default ResearchAddModal;
