import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  faAdd,
  faRedo,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ContentFormatButton from "../../components/ui/toggle-format/toggle-format";
import PersonnelTab from "./personnel-tab/PersonnelTab";
import { appActions } from "../../store/app-slice";
import { svGetPersonnels } from "../../services/personnel.service";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import ButtonUI from "../../components/ui/button/button";
import "./personnel.scss";

const Personnel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["personnel-page"]);
  const pageAvailable = useSelector(
    (state) => state.app.frontOffice.pageAvailable
  );
  const language = useSelector((state) => state.app.language);
  const [personnelTab, setPersonnelTab] = useState("0");
  const [isRowDisplay, setIsRowDisplay] = useState(false);
  const [personnelData, setPersonnelData] = useState([]);
  const [pageControl, setPageControl] = useState("");
  const [refreshData, setRefreshData] = useState(0);
  const [maxPriority, setMaxPriority] = useState(0);
  const [spawnActive, setSpawnActive] = useState(true);
  const [personnelModalAdd, setPersonnelModalAdd] = useState(false);
  const [textSearch, setTextSearch] = useState("");

  useEffect(() => {
    setPersonnelData([]);
    dispatch(appActions.isSpawnActive(spawnActive));
    svGetPersonnels(language).then((res) => {
      if (res.status === 200) {
        setPersonnelData(res.data.data);
        setMaxPriority(res.data.maxPriority)
      } else {
        console.log(res);
      }
      dispatch(appActions.isSpawnActive(false));
      setSpawnActive(false);
    });
  }, [refreshData, language]);

  const OnChangePageControlHandler = (e) => {
    setPageControl(e.target.value);
  };

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  return (
    <section id="personnel-page">
      <HeadPageComponent
        h1={t("บุคลากร")}
        icon={<FontAwesomeIcon icon={faPeopleGroup} />}
        breadcrums={[{ title: t("บุคลากร"), link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("ดึงข้อมูล")}
              </ButtonUI>
            </h2>
            <ContentFormatButton
              isRowDisplay={isRowDisplay}
              setIsRowDisplay={setIsRowDisplay}
            />
            {false && (
              <FormControl className="searchpage" variant="standard">
                <InputLabel id="filter-pgae">
                  {t("SelectPageControl")}
                </InputLabel>
                <Select
                  labelId="personnel-page"
                  autoWidth
                  id="filter-page"
                  label={t("SelectPageControl")}
                  className="input-page"
                  size="small"
                  onChange={OnChangePageControlHandler}
                  value={pageControl}
                >
                  <MenuItem value="">{t("SelectPageControlNone")}</MenuItem>
                  {pageAvailable &&
                    pageAvailable.map((menu) => (
                      <MenuItem key={menu.id} value={menu.id}>
                        {menu.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <div className="action-right">
              <TextField
                value={textSearch}
                label="ค้นหา"
                id="outlined-size-small"
                size="small"
                onChange={(e) => setTextSearch(e.target.value)}
              />
              <ButtonUI
                onClick={() => setPersonnelModalAdd(true)}
                className="btn-add-personnel"
                on="create"
                icon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t("เพิ่ม")}
              </ButtonUI>
            </div>
          </div>
        </div>
        <PersonnelTab
          personnelModalAdd={personnelModalAdd}
          setPersonnelModalAdd={setPersonnelModalAdd}
          setRefreshData={() => setRefreshData(refreshData + 1)}
          pageControl={pageControl}
          personnelTab={personnelTab}
          setPersonnelTab={setPersonnelTab}
          personnelData={personnelData}
          isRowDisplay={isRowDisplay}
          textSearch={textSearch}
          maxPriority={maxPriority}
        />
      </div>
    </section>
  );
};

export default Personnel;
