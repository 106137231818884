import React, { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Paper } from "@mui/material";
import ButtonUI from "../../../components/ui/button/button";
import DateMoment from "../../../components/ui/date-moment/date-moment";
import { svDeleteMessages } from "../../../services/messages.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SwalUI from "../../../components/ui/swal-ui/swal-ui";
const modalSwal = withReactContent(Swal);

function MessagesContactTable(props) {
  const { t, setRefreshData, filteredData, uPermission, setMessagesRead,setOpen } =
    props;
  const columns = [
    {
      flex: 1,
      field: "topic",
      headerName: t("ColumnsTopoic"),
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      headerClassName: "table-columns",
    },
    {
      flex: 1,
      field: "created_at",
      headerName: t("ColumnsDate"),
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <p>{<DateMoment format={"LLL"} date={param.row.created_at} />}</p>
        </>
      ),
    },
    {
      flex: 1,
      field: "fullname",
      headerName: t("ColumnsFullname"),
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      headerClassName: "table-columns",
    },
    {
      flex: 1,
      field: "email",
      headerName: t("ColumnsEmail"),
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      headerClassName: "table-columns",
    },
    {
      flex: 1,
      field: "message",
      headerName: t("ColumnsMessage"),
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      headerClassName: "table-columns",
    },
    {
      flex: 1,
      field: "action",
      headerName: t("DataGridHandle"),
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
         <div className="box-action">
              <ButtonUI
                onClick={() => editHandle(params.row)}
                on="edit"
                className="btn-custom btncancel"
                icon={<FontAwesomeIcon icon={faEye} />}
                width={"xxs"}
              >
                {t("")}
              </ButtonUI>
              {(uPermission.superAdmin||uPermission.admin)&&(
                <ButtonUI
                onClick={() => deleteHandle(params.row)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
              >
                {t("")}
              </ButtonUI>
              )}
              
            </div>
        </>
      ),
    },
  ];

  const editHandle = (row) => {
    console.log(row);
    setMessagesRead(row);
    setOpen(true);
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeleteMessages(row.id).then((res) => {
            console.log(res);
           
            if (res.status === 200) {
              SwalUI({
                status: res.status,
                description: res.data.description,
              });
              if (res.status) {
                setRefreshData((prev) => prev + 1);
              }
              //  return false;
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      });
  };

  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1ren" }}>
        <Paper>
          <DataGrid
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            rowHeight={100}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFistButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}

export default MessagesContactTable;
