import { DataGrid } from "@mui/x-data-grid";
import { Fragment } from "react";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import ButtonUI from "../../../components/ui/button/button";
import { svDeleteResearch } from "../../../services/research.service";
import { Switch } from "@mui/material";
import axios from "axios";

const modalSwal = withReactContent(Swal);

export default function DataGridResearch({
  filteredData,
  setOpenEdit,
  setResearchEdit,
  setRefreshData,
  language,
  setIsEdit,
}) {
  const { t } = useTranslation("packagecate-page");

  const columns = [
    {
      //   width: 150,
      flex: 1/2,
      field: "id",
      headerName: "ID",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: 150,
      flex: 3,
      field: "title",
      headerName: "ชื่องานวิจัย",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 2,
      field: "details",
      headerName: "รายละเอียด",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 2,
      field: "researchers",
      headerName: "ผู้ทำวิจัย",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <div style={{ whiteSpace: 'break-spaces', padding: '1rem'}}>
          <p>{params.row.researchers}</p>
        </div>
      )
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "year",
      headerName: "ปีที่ทำวิจัย",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
    },
    {
      //   width: isHovered ? 290 : 150,
      flex: 1,
      field: "link",
      headerName: "ลิงก์",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <a href={params.row.link} target="_blank" style={{ color: "blue", textDecoration: "underline" }}>
          <p>LINK</p>
        </a>
      )
    },
    {
      //   width: 140,
      flex: 1 / 2,
      field: "pin",
      headerName: "PIN",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            color="warning"
            defaultChecked={param.row.pin ? true : false}
            onChange={(e) =>
              patchPin(e.target.checked,param.row.id)
            }
          />
        </>
      ),
    },
    {
      //   width: 140,
      flex: 1,
      field: "display",
      headerName: "การแสดงผล",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (param) => (
        <>
          <Switch
            defaultChecked={param.row.display ? true : false}
            onChange={(e) =>
              patchDisplay(e.target.checked,param.row.id)
            }
          />
        </>
      ),
    },
    {
      //   width: 90,
      flex: 1,
      field: "action",
      headerName: "จัดการ",
      headerAlign: "left",
      align: "left",
      headerClassName: "table-columns",
      renderCell: (params) => (
        <>
          {params.row.language === language ? (
            <div className="box-action">
              <ButtonUI
                onClick={() => editHandle(params.row)}
                on="edit"
                className="btn-custom onEdit"
                icon={<FontAwesomeIcon icon={faEdit} />}
                width={"xxs"}
              >
                {t("")}
              </ButtonUI>
              <ButtonUI
                onClick={() => deleteHandle(params.row)}
                on="delete"
                className="btn-custom onDelete"
                icon={<FontAwesomeIcon icon={faTrash} />}
                width={"xxs"}
              >
                {t("")}
              </ButtonUI>
            </div>
          ) : (
            <ButtonUI
              onClick={() => addHandle(params.row)}
              on="add"
              className="btn-custom onAdd"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {t("Add")}
            </ButtonUI>
          )}
        </>
      ),
    },
  ];

  const editHandle = (row) => {
    setResearchEdit(row);
    setOpenEdit(true);
    setIsEdit(true)
  };

  const addHandle = (row) => {
    setResearchEdit(row);
    setOpenEdit(true);
    setIsEdit(false)
  };

  const patchPin = (checked, _id) => {
    axios.patch(`research/updatepin/${_id}`, { pin: checked, language: language }).then(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  };

  const patchDisplay = (checked, _id) => {
    axios.patch(`research/updatedisplay/${_id}`, { display: checked, language: language }).then(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  };

  const deleteHandle = (row) => {
    modalSwal
      .fire({
        icon: "warning",
        title: "Are you sure?",
        text: "I want to delete this data!",
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeleteResearch(row.id, row.language).then((res) => {
            if (res.status === 200) {
              setRefreshData(prev => prev + 1)
              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
            } else {
              SwalUI({
                status: res.data.status,
                description: res.data.description,
              });
            }
          });
        }
      });
  };

  const rowsClassName = "table-rows";

  return (
    <Fragment>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Paper>
          <DataGrid
            // rowHeight={250}
            getRowHeight={() => 'auto'} // set row height auto
            getRowId={(row) => row.id}
            getRowClassName={() => rowsClassName}
            sx={{ fontSize: "14px", border: "none" }}
            rows={filteredData}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    </Fragment>
  );
}
