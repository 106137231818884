import axios from "axios";
import { useSelector } from "react-redux";

// const language = useSelector((state) => state.app.language);

export const svMailAll = () => {
  return axios
    .get(`mail/data`)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdateReaded = (id) => {
  return axios
    .patch(`mail/updatereaded/${id}`)
    .then((res) => res)
    .catch((err) => err);
};

export const svDeleteMail = (id) => {
  return axios
  .delete(`mail/delete/${id}`)
  .then((res) => res)
  .catch((err) => err);
};


export const svContactMs = () => {
  return axios
    .get(`contactms/data`)
    .then((res) => res)
    .catch((err) => err);
};