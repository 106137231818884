import axios from "axios";

export const getCategoryList = (language) => {
    return axios.get(`productagricul/category?language=${language}`)
        .then((res) => res)
        .catch((error) => error);
};

export const getProduct = (language) => {
    return axios.get(`productagricul/product?language=${language}`)
        .then((res) => res)
        .catch((error) => error);
}

export const svCreateProduct = (formData) => {
    return axios
        .post(`productagricul/create`, formData)
        .then((res) => res)
        .catch((err) => err);
}

export const svUpdateProduct = (id, formData) => {
    return axios
        .post(`productagricul/update/${id}`, formData)
        .then((res) => res)
        .catch((err) => err);
}

export const svDeleteProduc = (id,language) =>{
    return axios
    .delete(`productagricul/destroy/${id}/${language}`)
    .then((res) => res)
    .catch((err) => err);
}