import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  faEyeSlash,
  faFolderOpen,
  faLanguage,
  faEnvelope,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { TablePagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import SwalUI from "../../../components/ui/swal-ui/swal-ui";
import { svDeletePersonnel } from "../../../services/personnel.service";
import PersonnelAddModal from "../personnel-modal/PersonnelAddModal";
import PersonnelEditModal from "../personnel-modal/PersonnelEditModal";
import ContentCardUI from "../../../components/ui/content-card/content-card";

import { appActions } from "../../../store/app-slice";

const modalSwal = withReactContent(Swal);

const PersonnelTab = (props) => {
  const {
    personnelModalAdd,
    setPersonnelModalAdd,
    personnelTab,
    personnelData,
    isRowDisplay,
    textSearch,
    maxPriority,
  } = props;
  const { t } = useTranslation("personnel-page");

  const isSuerperAdmin = useSelector(
    (state) => state.auth.userPermission.superAdmin
  );
  const [totalData, setTotalData] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [limited, setLimited] = useState({ begin: 0, end: rowsPerPage });
  const [filteredData, setFilteredData] = useState([]);
  const [items, setItems] = useState(null);
  const [personnelModalEdit, setPersonnelModalEdit] = useState({
    isEdit: false,
    isOpen: false,
  });

  const tabLists = [
    {
      value: "0",
      title: t("ทั้งหมด"),
      icon: <FontAwesomeIcon icon={faFolderOpen} />,
    },
    {
      value: "1",
      title: t("แสดงผล"),
      icon: <FontAwesomeIcon icon={faNewspaper} />,
    },
    {
      value: "2",
      title: t("ไม่แสดง"),
      icon: <FontAwesomeIcon icon={faEyeSlash} />,
    },
  ];

  useEffect(() => {
    setFilterData().then((res) => {
      setTotalData(res.length);
      setFilteredData(res.slice(limited.begin, limited.end));
    });
  }, [personnelTab, personnelData, page, rowsPerPage, textSearch]);

  const setFilterData = async () => {
    const filtered = await personnelData.filter((f) => {
      /* กรอง Tab */
      if (personnelTab === "1" && f.display === 0) {
        return false;
      } else if (personnelTab === "2" && f.display === 1) {
        return false;
      }

      return f;
    });

    if (!textSearch || textSearch.trim() === "") {
      return filtered;
    } else {
      const fitered = filtered?.filter(
        (item) =>
          item &&
          (item.name.toLowerCase().includes(textSearch.toLowerCase()) ||
            item.position.toLowerCase().includes(textSearch.toLowerCase()) ||
            item.email.toLowerCase().includes(textSearch.toLowerCase()))
      );
      return fitered;
    }
  };

  const handleTabChange = (event, newValue) => {
    props.setPersonnelTab(newValue);
  };

  /* Pagination */
  const handleChangePage = (event, newPage) => {
    setLimited({
      begin: newPage * rowsPerPage,
      end: (newPage + 1) * rowsPerPage,
    });
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    let rowPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowPage);
    setLimited({ begin: 0, end: rowPage });
    setPage(0);
  };

  const addHandler = (item) => {
    setItems(item);
    setPersonnelModalEdit({
      isEdit: false,
      isOpen: true,
    });
  };

  const editHandler = (item) => {
    setItems(item);
    setPersonnelModalEdit({
      isEdit: true,
      isOpen: true,
    });
  };
  const deleteHandler = (item) => {
    let buttonIcon = item.is_maincontent === 1 ? "question" : "warning";

    modalSwal
      .fire({
        icon: buttonIcon,
        title: "Delete Personnel",
        text: `Do you want to delete "${item.name}" ?`,
        confirmButtonText: "Yes, delete it",
        confirmButtonColor: "#e11d48",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          svDeletePersonnel(item.id, item.language).then((res) => {
            if (res.status === 200) {
              SwalUI({ status: res.data.status, description: res.data.description });
              props.setRefreshData((prev) => prev + 1);
            } else {
              SwalUI({ status: res.data.status, description: res.data.description });
            }
          });
        }
      });
  };
  return (
    <Fragment>
      <Box className="personnel-tab-section" sx={{ width: "100%" }}>
        <TabContext value={personnelTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              {tabLists.map((tab) => (
                <Tab
                  className="personnel-tab-header"
                  value={tab.value}
                  key={tab.value}
                  icon={tab.icon}
                  label={t(tab.title)}
                />
              ))}
            </TabList>
          </Box>
          {tabLists.map((tab) => (
            <TabPanel
              className={`personnel-tab-body ${isRowDisplay ? "asRow" : "asColumn"}`}
              value={tab.value}
              key={tab.value}
            >
              <div className="item-list">
                {filteredData.map((item, index) => (
                  <ContentCardUI
                    key={index}
                    onAddClick={() => addHandler(item)}
                    onEditClick={() => editHandler(item)}
                    onDeleteClick={() => deleteHandler(item)}
                    mainContent={item.is_maincontent}
                    allowDelete={true}
                    className="personnel-card-content"
                    data={{
                      alt: item.name,
                      image: item.profile_image,
                      language: item.language,
                    }}
                    isRowDisplay={isRowDisplay}
                  >
                    <h3 className="title">
                      {isSuerperAdmin && (
                        <span className={"id"} title="ref id">
                          [ {item.id} ]
                        </span>
                      )}
                      {item.name}
                      {item.is_manager ? (
                        <span className={"id"} title="ref id">
                          {" "}
                          ( หัวหน้าสาขา )
                        </span>
                      ) : (
                        <></>
                      )}
                    </h3>
                    <p className="desc">
                      {" "}
                      <FontAwesomeIcon icon={faUserTie} /> {item.position}
                    </p>
                    <p className="desc">
                      {" "}
                      <FontAwesomeIcon icon={faEnvelope} /> {item.email}
                    </p>
                    <p className="editor">
                      <span className="fa-icon" title="editor">
                        <FontAwesomeIcon icon={faLanguage} />
                      </span>
                      <b>{item.language.toUpperCase()}</b>
                    </p>
                  </ContentCardUI>
                ))}
              </div>
              <TablePagination
                component="div"
                count={totalData}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
              />
            </TabPanel>
          ))}
        </TabContext>
      </Box>
      {personnelModalAdd && (
        <PersonnelAddModal
          setRefreshData={props.setRefreshData}
          isOpen={personnelModalAdd}
          setClose={setPersonnelModalAdd}
          maxPriority={maxPriority}
        />
      )}
      {personnelModalEdit.isOpen && (
        <PersonnelEditModal
          items={items}
          setRefreshData={props.setRefreshData}
          isEdit={personnelModalEdit.isEdit}
          isOpen={personnelModalEdit.isOpen}
          setClose={setPersonnelModalEdit}
        />
      )}
    </Fragment>
  );
};

export default PersonnelTab;
