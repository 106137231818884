import * as React from "react";
import { useSelector } from "react-redux";
import { Link, useMatch, useResolvedPath, Navigate } from "react-router-dom";
const NavLink = ({ children, to, ...props }) => {
  const defaultURL = useSelector((state) => state.app.defaultURL)
  let resolved = useResolvedPath(defaultURL + to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    
    <div className={`${props.liClass} ${match ? "active" : ""}`} >
      <Link
        onClick={props.onClick}
        to={defaultURL + to}
        className={props.className}
        title={props.title} >
        {children}
      </Link>
    </div>
  );
};

export default NavLink;
