import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { faGamepad, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonUI from "../../components/ui/button/button";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import { appActions } from "../../store/app-slice";
import Tables from "./Tables";

import "./dashboard.scss";
import { getDashboardDetails } from "../../services/dashboard.service";

const DashboardPage = () => {
  const { t } = useTranslation(["dashboard-page"]);
  const language = useSelector((state) => state.app.language);
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(0);
  const [orderData, setOrderData] = useState([]);
  const [mailServiceData, setMailServiceData] = useState([]);
  const [mailContactData, setMailContactData] = useState([]);

  useEffect(() => {
    dispatch(appActions.isSpawnActive(true));
    getDashboardDetails().then((res) => {
      if (res.status) {
        const result = res.data
        setOrderData(result.orders)
        setMailServiceData(result.mailService)
        setMailContactData(result.mailContact)
      }
      dispatch(appActions.isSpawnActive(false));
    });
  }, [language, refreshData]);

  return (
    <section id="dashboard-page">
      <HeadPageComponent
        h1={"dashboardPage"}
        icon={<FontAwesomeIcon icon={faGamepad} />}
        breadcrums={[{ title: "dashboardPage", link: false }]}
      />

      <div className="card-control fixed-width">
        <div className="card-body">
          <Tables
            orderData={orderData}
            mailServiceData={mailServiceData}
            mailContactData={mailContactData}
          />
        </div>
      </div>
    </section>
  );
};

export default DashboardPage;
