import axios from "axios";

export const svGetPersonnels = (language) => {
  return axios
    .get(`personnelall?language=${language}`)
    .then((res) => res)
    .catch((err) => err);
};

export const svCreatePersonnel = (formData) => {
  return axios
    .post(`personnel/create`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svUpdatePersonnel = (id, formData) => {
  return axios
    .post(`personnel/update/${id}`, formData)
    .then((res) => res)
    .catch((err) => err);
};

export const svDeletePersonnel = (id, language) => {
  return axios
    .delete(`personnel/delete/${id}/${language}`)
    .then((res) => res)
    .catch((err) => err);
};
