import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Modal, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import ButtonUI from "../../components/ui/button/button";
import { useTranslation } from "react-i18next";

export default function ContactMsModal(props) {
  const { open, setOpen, mailShow } = props;
  const webPath = useSelector((state) => state.app.webPath);
  const { t } = useTranslation("contactms-page");

  return (
    <>
      <Modal
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box id="contactms-modal">
          <div className="card-control">
            <div className="card-head">
              <div className="head-action">
                <Typography className="head-title">
                  <FontAwesomeIcon icon={faCircleInfo} /> {t("ข้อมูล")}
                </Typography>
              </div>
              <div className="head-right">
                <h2 className="head-title">
                  <Typography>
                    <FontAwesomeIcon icon={faEnvelope} /> ข้อความ
                  </Typography>
                </h2>
              </div>
            </div>
            <div className="card-body">
              <Box
                className="mail-details"
                sx={{
                  width: "100%",
                  display: "grid",
                  gap: 1,
                  gridTemplateColumns: "repeat(2, 1fr)",
                  marginBottom: ".5rem",
                }}
              >
                <Typography variant="subtitle1">
                  ประเภทการติดต่อ : ข้อความติดต่อ
                </Typography>
                <Typography variant="subtitle1">
                  ชื่อ : {mailShow.firstname + " " + (mailShow.lastname ? mailShow.lastname : "")}
                </Typography>
                <Typography variant="subtitle1">
                  เบอร์โทรติดต่อ : {mailShow.phone_number}
                </Typography>
                <Typography variant="subtitle1">
                  อีเมล์ : {mailShow.email}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  ข้อความ :
                </Typography>
                <Typography variant="subtitle1">
                  {mailShow.messages}
                </Typography>
              </Box>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
