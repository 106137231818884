import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HeadPageComponent from "../../components/layout/headpage/headpage";
import { useDispatch, useSelector } from "react-redux";
import ContentFormatButton from "../../components/ui/toggle-format/toggle-format";
import CategoryTab from "./category-tab/category-tab";
import ModalAddCategory from "./category-modal/cate-add-modal";
import ButtonUI from "../../components/ui/button/button";
import { appActions } from "../../store/app-slice";
import { getCategory } from "../../services/category.service";
import ModalEditCategory from "./category-modal/cate-edit-modal";

import "./category.scss";
import { faAdd, faSitemap, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@mui/material";

const CategoryPage = () => {
  const { t } = useTranslation(["category-page"]);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const [categoryTab, setCategoryTab] = useState("0");
  const [isRowDisplay, setIsRowDisplay] = useState(true);
  const [menuList, setMenuList] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [modalAddCate, setModalAddCate] = useState(false);
  const [modalEditCate, setModalEditCate] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [spawnActive, setSpawnActive] = useState(true);
  const [textSearch, setTextSearch] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const uPermission = useSelector((state) => state.auth.userPermission);

  useEffect(() => {
    setCategoryData([])
    dispatch(appActions.isSpawnActive(true));
    getCategory(language)
      .then((res) => {
        setCategoryData(res.data);
        setMenuList(res.menu);
        dispatch(appActions.isSpawnActive(false));
        setSpawnActive(false);
      })
      .catch(() => {
        setSpawnActive(false);
        dispatch(appActions.isSpawnActive(false));
      });
  }, [refreshData, language]);

  const refreshHandle = () => {
    setSpawnActive(true);
    setRefreshData((prev) => prev + 1);
  };

  return (
    <section id="category-page">
      <HeadPageComponent
        h1={t("TitlePage")}
        icon={<FontAwesomeIcon icon={faSitemap} />}
        breadcrums={[{ title: t("TitlePage"), link: false }]}
      />
      <div className="card-control fixed-width">
        <div className="card-head">
          <div className="head-action">
            <h2 className="head-title">
              <ButtonUI
                onClick={refreshHandle}
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faRedo} />}
              >
                {t("Fetch")}
              </ButtonUI>
            </h2>

            <ContentFormatButton
              isRowDisplay={isRowDisplay}
              setIsRowDisplay={setIsRowDisplay}
            />

            <div className="action-right">
              <TextField
                value={textSearch}
                label={t("Search")} //ค้นหา
                id="outlined-size-small"
                size="small"
                onChange={(e) => setTextSearch(e.target.value)}
              />
              {uPermission.superAdmin && (<ButtonUI
                onClick={() => setModalAddCate(true)}
                className="btn-add-category"
                on="create"
                isLoading={false}
                icon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t("AddCate")}
              </ButtonUI>)}
            </div>
          </div>
        </div>

        <CategoryTab
          t={t}
          totalData={totalData}
          setTotalData={setTotalData}
          setRefreshData={setRefreshData}
          isRowDisplay={isRowDisplay}
          setModalEditCate={setModalEditCate}
          tabSelect={categoryTab}
          setCategoryTab={setCategoryTab}
          categoryData={categoryData}
          setFilteredData={setFilteredData}
          filteredData={filteredData}
          textSearch={textSearch}
        />

        <ModalAddCategory
          totalData={totalData}
          setRefreshData={setRefreshData}
          menuList={menuList}
          isOpen={modalAddCate}
          setClose={setModalAddCate}
          categoryData={categoryData}
        />

        <ModalEditCategory
          categoryData={categoryData}
          setRefreshData={setRefreshData}
          menuList={menuList}
          isOpen={modalEditCate}
          setClose={setModalEditCate}
        />
      </div>
    </section>
  );
};

export default CategoryPage;
