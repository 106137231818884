import { useState, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
  Box,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faRedo,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

import PreviewImageUI from "../../../../components/ui/preview-image/preview-image";
import FieldsetUI from "../../../../components/ui/fieldset/fieldset";
import ButtonUI from "../../../../components/ui/button/button";
import SwalUI from "../../../../components/ui/swal-ui/swal-ui";
import { svCreateProducCate } from "../../../../services/productServices/productcate.service";

function CategoryAddModal(props) {
  const { t, setRefreshData, open, setOpen, maxPriority } = props;
  const [previews, setPreviews] = useState({ src: "", file: null, name: null });
  const [isError, setIsError] = useState({
    thumbnail: false,
    title: false,
  });
  const initProductData = {
    title: "",
    details: "",
    description: "",
    networks_id: "",
    imageName: "",
    thumbnail_title: "",
    thumbnail_alt: "",
    display: true,
    pin: false,
    priority: maxPriority + 1,
  };
  // const dispatch = useDispatch();
  // const uploadPath = useSelector((state) => state.app.uploadPath);
  // const language = useSelector((state) => state.app.language);
  const defaultLanguage = useSelector((state) => state.app.defaultLanguage);
  const [addData, setAddData] = useState(initProductData);
  const [isFetching, setIsFetching] = useState(false);
  const scrollRef = useRef(null);

  const setPreviewHandler = (data) => {
    setAddData({ ...addData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setAddData((prevState) => {
        return { ...prevState, priority: addData.priority + 1 };
      });
    } else if (addData.priority > 1) {
      setAddData((prevState) => {
        return { ...prevState, priority: addData.priority - 1 };
      });
    }
  };

  const addValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      thumbnail: previews.file === null || !previews.file,
      title: addData.title.trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("Image", previews.file);
        formData.append("ImageName", addData.imageName);
      }

      formData.append(
        "thumbnail_title",
        addData.thumbnail_title ? addData.thumbnail_title : ""
      );
      formData.append(
        "thumbnail_alt",
        addData.thumbnail_alt ? addData.thumbnail_alt : ""
      );

      formData.append("title", addData.title);
      formData.append("details", addData.details ? addData.details : "");
      formData.append(
        "description",
        addData.description ? addData.description : ""
      );
      formData.append("display", addData.display ? 1 : 0);
      formData.append("pin", addData.pin ? 1 : 0);
      formData.append("priority", addData.priority);
      formData.append("language", defaultLanguage);
      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);

    svCreateProducCate(formData).then((res) => {
      setOpen(false);
      setIsFetching(false);
      if (res.status === 200) {
        SwalUI({ status: res.data.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };

  return (
    <Fragment>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="category-add-modal">
          <section id="category-add-page">
            <div className="card-control">
              <div className="card-head">
                <div className="head-action">
                  <div className="head-title">
                    <FontAwesomeIcon icon={faAdd} /> {t("AddCate")}
                  </div>
                </div>
              </div>
              <div className="card-body" ref={scrollRef}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                >
                  {/* ข้อมูลรูปภาพ */}
                  <FieldsetUI
                    className={`image-setting ${
                      isError.thumbnail ? "error" : ""
                    }`}
                    label={t("Image Info")}
                  >
                    <PreviewImageUI
                      className="add-image"
                      previews={previews}
                      setPreviews={setPreviewHandler}
                      setData={setAddData}
                    />
                    <div className="image-detail">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return { ...prevState, imageName: e.target.value };
                          })
                        }
                        value={addData.imageName}
                        className={`text-field-custom ${
                          !previews.file ? "inp-hidden" : ""
                        }`}
                        fullWidth={true}
                        error={false}
                        id="image-name"
                        label={t("ModalImageName")}
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_title: e.target.value,
                            };
                          })
                        }
                        value={addData.thumbnail_title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-title"
                        label={t("ModalImageTitle")}
                        size="small"
                      />
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              thumbnail_alt: e.target.value,
                            };
                          })
                        }
                        value={addData.thumbnail_alt}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="image-tag"
                        label={t("ModalAltDescription")}
                        size="small"
                      />
                    </div>
                  </FieldsetUI>
                  {/* รายละเอียด */}
                  <div className="category-details">
                    <h3 className="category-detail-title ">
                      {t("ModalDetail")}
                    </h3>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return { ...prevState, title: e.target.value };
                          })
                        }
                        value={addData.title}
                        className="text-field-custom"
                        fullWidth={true}
                        error={isError.title}
                        id="ad-title"
                        label={t("ModalCategoriesName")}
                        size="small"
                      />
                    </div>
                    
                    {/*  
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              details: e.target.value,
                            };
                          })
                        }
                        value={addData.details}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-details"
                        label={t("ModalCategoriesDetail")}//"รายละเอียดหมวดหมู่"
                        size="small"
                      />
                    </div>
                    <div className="input-full">
                      <TextField
                        onChange={(e) =>
                          setAddData((prevState) => {
                            return {
                              ...prevState,
                              description: e.target.value,
                            };
                          })
                        }
                        value={addData.description}
                        className="text-field-custom"
                        fullWidth={true}
                        error={false}
                        id="ad-description"
                        label={t("ModalCategoriesDescription")}//"คำอธิบายหมวดหมู่"
                        size="small"
                        multiline
                      />
                    </div>
                    */}

                    {/* Settings */}
                    <div className="group-settings">
                      <h3 className="post-detail-title">{t("Settings")}</h3>
                      <div className="setting-controls">
                        <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setAddData({
                                      ...addData,
                                      display: e.target.checked,
                                    })
                                  }
                                  checked={addData.display ? true : false}
                                />
                              }
                              label={t("display")}//การแสดงผล
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div>
                        {/* <div className="switch-form">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={(e) =>
                                    setAddData({
                                      ...addData,
                                      pin: e.target.checked,
                                    })
                                  }
                                  checked={addData.pin ? true : false}
                                />
                              }
                              label={t("Pin")}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </div> */}
                        <div className="input-group-settings">
                          <div className="inp">
                            <ButtonUI
                              color="error"
                              onClick={(e) => priorityHandler(false)}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </ButtonUI>
                            <span className="title">
                              {t("Priority")} {addData.priority}
                            </span>
                            <ButtonUI onClick={(e) => priorityHandler(true)}>
                              <FontAwesomeIcon icon={faAdd} />
                            </ButtonUI>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
              {/* ปุ่ม */}
              <div className="btn-action">
                <ButtonUI
                  // loader={true}
                  isLoading={isFetching}
                  onClick={addValidators}
                  className="btn-save"
                  on="save"
                  width="md"
                >
                  {t("save")}
                </ButtonUI>
                <ButtonUI
                  onClick={() => setOpen(false)}
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  className="btn-cancel"
                  on="cancel"
                  width="md"
                >
                  {t("cancel")}
                </ButtonUI>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default CategoryAddModal;
